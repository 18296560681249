
import { getFilters, getTagsFilters } from "../services/clientManagementService";
import { SETFILTERS } from "../config";
import { useDispatch, useSelector } from "react-redux";
import { setFilters } from "../slices/filtersSlice";

export default function FilterItem({selectedFilters,setSelectedFilters,name,label}){
    const filterName=name;
    const dispatch = useDispatch()
    const filters = useSelector((state) => state.filters.filters)
     let values = [...filters[filterName] ]
     if(values[0]!=="All")
      values.unshift('All')
  


   async function getFiltersOfWebsite(website) {
   
    try{
        const sorted=['women', 'men', 'kids', 'other'];

        let newDepartments = await getFilters('departments',website!=='All'?website:null)
        newDepartments = newDepartments.data.deps.map(item => item._id);
        newDepartments.sort(); 
       const  department=newDepartments.filter((dep)=> dep)
      
       
        let target_audience = await getFilters('target_audience',website!=='All'?website:'trendyol')
        target_audience = target_audience.data.terget_audience.map(item => item._id); 
    
        target_audience.sort((a, b) => sorted.indexOf(a) - sorted.indexOf(b))



        const tagsfilter=(await getTagsFilters({website})).data;
            let foundedTags={};
         tagsfilter.forEach((tag)=>{
       
            foundedTags[tag.filter]=tag.values
          
        })

        dispatch(setFilters({website:filters.website,...foundedTags, target_audience,department}))
        //to use filters outside components in any service
         SETFILTERS({website:filters.website,...foundedTags , target_audience,department})

    }catch(error){
        console.log(error)
    }
  ;
   
   
    }
    async function getFiltersOftargetAudince(target_audience) {
   
        try{
            
    
            const tagsfilter=(await getTagsFilters({target_audience,department:selectedFilters['department'],website:selectedFilters['website']})).data;
                let foundedTags={};
             tagsfilter.forEach((tag)=>{
           
                foundedTags[tag.filter]=tag.values
              
            })
    
            dispatch(setFilters({website:filters.website,...foundedTags,target_audience :filters.target_audience,department:filters.department}))
            //to use filters outside components in any service
             SETFILTERS({website:filters.website,...foundedTags,target_audience :filters.target_audience,department:filters.department})
    
        }catch(error){
            console.log(error)
        }
      ;
       
       
        }

    async function getFiltersOfDepartment(department) {
   
        try{
            
    
            const tagsfilter=(await getTagsFilters({department,target_audience:selectedFilters['target_audience'],website:selectedFilters['website']})).data;
                let foundedTags={};
             tagsfilter.forEach((tag)=>{
           
                foundedTags[tag.filter]=tag.values
              
            })
    
            dispatch(setFilters({website:filters.website,...foundedTags,target_audience :filters.target_audience,department:filters.department}))
            //to use filters outside components in any service
             SETFILTERS({website:filters.website,...foundedTags,target_audience :filters.target_audience,department:filters.department})
    
        }catch(error){
            console.log(error)
        }
      ;
       
       
        }


    async function handlValue(e){

   
        let eValue=e.target.value;
    
    
    switch(filterName){
        case 'website':{
          await getFiltersOfWebsite(eValue)
           setSelectedFilters((prev)=>({...prev,department:'All',target_audience:'All',[filterName]:eValue}))
             break;
        }
        case 'department':{
            await   getFiltersOfDepartment(eValue)
            setSelectedFilters((prev)=>({...prev,[filterName]:eValue}))
              break;
         }
         case 'target_audience':{
            await   getFiltersOftargetAudince(eValue)
            setSelectedFilters((prev)=>({...prev,[filterName]:eValue}))
              break;
         }
    
        

        default: {
            setSelectedFilters((prev)=>({...prev,[filterName]:eValue}))

        }
    }

        
    }
    
    return(
        
        <div className="form-group ">
            <label style={websiteLabel}>{label}</label>
            <select className="form-select" value={selectedFilters[filterName]} onChange={handlValue}>
                {values.map((el)=>{
                            return (<option key={el}     style={{fontFamily:"Space Grotesk"}} value={el}>{el}</option>)
                })}
            </select>
        </div>          
    )
}




let websiteLabel ={
    display:"block",
    float:"left",
    marginLeft:"3px",
    marginBottom:"5px",
    marginTop:'10px',
    fontFamily:"Signika",
    fontWeight:"400",
    fontSize:"16px"
}
