import { useEffect, useState } from "react";

import NewFileUploader from "../component/NewFileUplader";
import SideBarComponent from "../ui-components/SideBarComponent";
import ErrorMessageComponent from './../component/ErrorMessageComponent';
import { getSimilarsList } from "../services/clientManagementService";
import ProgressBarComponent from './../ui-components/ProgressBarComponent';
import { imageSearch } from "../services/imageSearchService";
import CardText from '../ui-components/Card';
import { Pagination } from '@mui/material';

export default function ImageSearch() {

    const [mainImg, setMainImg] = useState()
    const [filters, setFilters] = useState({
    website:'All',
    target_audience:'All',
    department:'All',
})

    const [lastDate, setlastDate] = useState()
    const [firstDate, setFirstDate] = useState()
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState()
    const [noDataMsg, setNoDataMsg] = useState()
    const [noDataIcon, setNoDataIcon] = useState()
    const [count, setCount] = useState(0)
    const [result, setResult] = useState([])
    const [currentPage, setCurrentPage] = useState([])
    const [searchKey, setSearchKey] = useState()




    useEffect(() => {

        getSimilarsList(filters).then((response) => {
            if (response?.data) {
                setlastDate(new Date(response?.data?.last_product_date))
                setFirstDate(new Date(response?.data?.first_product_date))

            }
        }).catch((err) => {
            console.log(err)
        })

        if (mainImg) {

            getImageSearchResult(1,'')
        }

    }, [filters, mainImg])

    function getImageSearchResult(page,search_key) {

        setLoading(true);
         setCurrentPage(page);
         setResult([])

        imageSearch(filters, page, mainImg,search_key).then((response) => {
            if (response.status === 200) {
                if (response?.data?.message?.length > 0) {
                    
                    setResult(response?.data.message)
                    setCount(response.data.message.count)
                    setSearchKey(response.data.search_key)


                }
                else {
    
                    
                    setNoDataIcon('../NoQualifiedData.svg')
                    setNoDataMsg('There’s no more data in this category. We’re brining in more data soon!')
                    setCount(0)

                }

            } else if (response.status === 204) {
                setNoDataIcon('../oops.svg')
                setNoDataMsg('No Data Found')
                setCount(0)
            }

            setLoading(false);

        }).catch((err) => {
            setErrorMessage(err?.message)
        })

    }




    return (<>

        {errorMessage ?
            <ErrorMessageComponent message={errorMessage} />
            :
            <div className="container-fluid">
                <div className="row ">
                <SideBarComponent  selectedFilters={filters} setSelectedFilters={setFilters}></SideBarComponent>

                     <div className="col-2" ></div>
                    <div className="d-none d-lg-block" style={verticalLine}></div>
                    <div className="col-12 col-lg-9 mx-auto  mt-5">
                        {/* first row that contain title and back button */}

                        <p className=" w-100 text-center mb-5   mx-auto" style={paragraphStyle}>Image Search for Fashion </p>

                        {result.length>0&& <p className=" text-center ">
                            <img src='../cloud-add.svg' style={{ height: "24px", width: "24px" }} alt="cloud" />
                            <span >The first item added  : <span className="px-1" style={{ background: "#F3F3F3" }}> {firstDate.toDateString()}</span> </span>
                            <span >The last item added : <span className="px-1" style={{ background: "#F3F3F3" }}>{lastDate.toDateString()}</span> </span>
                        </p>}

                        <div className="mt-2">
                            <NewFileUploader setImage={setMainImg}></NewFileUploader>
                        </div>

                        {loading ? <div className="mt-1">
                            <div className=" col-lg-4 col-9  m-auto">
                                <div style={fileUploaderStyle}>
                                    <img src={mainImg} style={imageAfterUplaod} className="rounded" alt="img"></img>
                                </div>
                            </div>
                            <div className="row col-lg-8 m-auto ">
                                
                                <ProgressBarComponent></ProgressBarComponent>
                            </div>
                        </div> :
                            <>
                                {result?.length > 0 ?

                                    <div className=" mb-5" >

                                        <div className="row col-lg-3 col-md-4 col-sm-6 col-9 m-auto mt-3">

                                            <div className="card border-0 mt-4">
                                                <img className="card-img-top rounded" src={mainImg} width={200} height={280} style={{ borderRadius: "10px" }} alt='img' />
                                                <div className="card-body mt-3">
                                                    <p className=" text-center  mx-auto w-100" style={countStyle}>Search results for this image</p>
                                                    <p className=" text-center  mx-auto w-100" style={countNumStyle}>{count} items found </p>

                                                </div>
                                            </div>

                                        </div>
                                        <div className="row col-12 mx-auto">
                                            {
                                                result.map((product) => {
                                                    return (
                                                        <div className="col-6 col-md-3"  key={product.product_id} > 
                                                        <CardText p={product} type={'image'} /> 
                                                        </div>
                                                        )
                                                })}
                                        </div>
                                        {Math.ceil(count / 16) > 1 && <Pagination count={Math.ceil(count / 16)} page={currentPage} className="d-flex justify-content-center mt-2" shape="rounded" onChange={(e,page)=>{getImageSearchResult(page,searchKey)}} />}
                                    </div>
                                    :

                                    <div className="mt-5">
                                        {noDataMsg && <>
                                            <img style={{ width: '100px', height: "100px" }} src={noDataIcon} width="100px" height="60px" alt='no data'></img>
                                            <p style={{ marginTop: "8px", fontFamily: "Signika" }}>{noDataMsg}</p>
                                        </>}

                                    </div>
                                }
                            </>}

                    </div>
                </div>
            </div>}
    </>

    )
}



const verticalLine = {
    "borderLeft": "1px solid #E6EDFF",
    "height": "700px",
    "marginTop": "25px",
    "width": "20px"
}

const paragraphStyle = {
    fontFamily: "Signika",
    fontWeight: "600",
    "lineHeight": "25px",
    "fontSize": "23px",

}

const v1PStyle = {
    "textAlign": "left",
    fontFamily: "Signika",
    fontWeight: "100",
    lineHeight: "1px"
}
const fileUploaderStyle = {
    "width": "220px",
    "height": "220px",
    "justifyContent": "center",
    "alignItems": "center",
    "margin": "auto",
    "cursor": "pointer",
    "position": "relative",
    "marginTop": "40px",
    "backgroundColor": "#F5F5F5",
    "borderRadius": "5px"
}


const imageAfterUplaod = {
    "width": "200px",
    "height": "200px",
    "zIndex": 1,
    "marginTop": "10px",
    "borderRadius": "5px",
    opacity: "0.5"
}

const countStyle = {
    fontFamily: "Signika",
    fontWeight: "500",
    "fontSize": "16px",

}
const countNumStyle = {
    fontFamily: "Signika",
    fontWeight: "400",
    "fontSize": "16px",
    color: "#888888"

}