import { createSlice } from '@reduxjs/toolkit'

const initialState = {
 filters:{  target_audience:[],
  department:[],
  website:[]}


}

export const filtersSlice = createSlice({
  name: 'filters',
  
  initialState,
  reducers: {
   
    setFilters: (state, action) => {

     state.filters=action.payload

      // Object.keys(action.payload).forEach(filter => {
      //   state[filter]=action.payload[filter]
      // });

    
    },
  },
})

// Action creators are generated for each case reducer function
export const { setFilters } = filtersSlice.actions

export default filtersSlice.reducer