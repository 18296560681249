import axios from "axios";
import { FILTERS, MANAGEMENT, SIMILARITY_V2, apiKey  } from "../config";
import { store } from "../store";
import { addPersona, setPersona, setPersonas, setUserData } from "../slices/userSlice";
import { resetBasket, resetPurchased, resetReturned, resetWishList } from "../slices/shopSlice";





export function createUser(email){

//   const body={
//     email,
//     age: 20,
//     country: "Egypt",
//     city: "Mokattam",
//     region: "Kattamya",
//     gender:"male"
// }
    
   axios.post(`${process.env.REACT_APP_API_GATEWAY}${MANAGEMENT}register/demo`,{email}).then((res)=>{
      let userId='';
    localStorage.setItem("email",email);
    localStorage.setItem('user_id',res.data.user._id)
    userId=res.data.user._id;
    getPersonas(userId).then(async (res)=>{
    
      if(res.data.data.length===0)
      {
        createPersona({ username: `me(${email})`,description:'empty'}).then((res)=>{
          store.dispatch(addPersona(res.data.data))
          store.dispatch(setPersona(res.data.data))
        })

      }
      else{

     store.dispatch(setPersona(res.data.data[0]))
        store.dispatch(setPersonas(res.data.data))

      // const wishList=  await  getWishList(res.data.data[0]._id)
      // store.dispatch(resetWishList(wishList.data))
      // const basket=  await  getBasket(res.data.data[0]._id)
      // store.dispatch(resetBasket(basket.data))
      // const purchased=  await  getPurchased(res.data.data[0]._id)
      // store.dispatch(resetPurchased(purchased.data))

      }

    })
    


   }).catch((err)=>{
    console.log(err)
   })

  }

export function createPersona(body){
const userId=localStorage.getItem('user_id')

body.user=userId
    
  return axios.post(`${process.env.REACT_APP_API_GATEWAY}${MANAGEMENT}personas`,body)

  }
  export function EditPersona(body,personaId){
   
        
      return axios.put(`${process.env.REACT_APP_API_GATEWAY}${MANAGEMENT}personas?personaId=${personaId}`,body)
    
      }
    
  
  export function getPersonas(userId){

      
    return axios.get(`${process.env.REACT_APP_API_GATEWAY}${MANAGEMENT}personas`,{params:{userId}})
  
    }
  



export function getFilters(filter,website){
 const  body={ "website":website };

    if(filter!=='websites')
    {
    return axios.post(`${process.env.REACT_APP_API_GATEWAY}${MANAGEMENT}products/${filter}`,
    website?body:{})
}
    else{
        
        return axios.get(`${process.env.REACT_APP_API_GATEWAY}${MANAGEMENT}products/${filter}`)

    }

}


export function getAllProductsCount(){
        
   return axios.get(`${process.env.REACT_APP_API_GATEWAY}${MANAGEMENT}products/products-count`)

   }

   

export function getSimilarsList(filters,pageIndex){

    const body={
         
        gender : filters['target_audience'] === "All" ?  FILTERS.target_audience:[filters["target_audience"]] ,
        website :filters["website"] === "All" ?FILTERS.website  :[filters["website"]] ,
        department: filters["department"] === "All" ?FILTERS.department :[filters["department"]],
        "page_index":pageIndex??1,
        "page_size":16
      };
      
    return axios.post(`${process.env.REACT_APP_API_GATEWAY}${MANAGEMENT}products/list-products`,body)
 
    }


  export function  getsimilarsCount(filters){
    const body = {
         
      gender : filters['target_audience'] === "All" ?  FILTERS.target_audience:[filters["target_audience"]] ,
      website :filters["website"] === "All" ?FILTERS.website  :[filters["website"]] ,
      department: filters["department"] === "All" ?FILTERS.department :[filters["department"]],
     }
    return axios.post(`${process.env.REACT_APP_API_GATEWAY}${MANAGEMENT}products/category-count`,body)
    }



    export function getWishList(prodId){
        
      return axios.get(`${process.env.REACT_APP_API_GATEWAY}${MANAGEMENT}favorites/persona/${prodId}`)
   
      }
      export function getBasket (prodId){
        
        return axios.get(`${process.env.REACT_APP_API_GATEWAY}${MANAGEMENT}carts/persona/${prodId}`)
     
        }
        export function getPurchased(prodId){
        
          return axios.get(`${process.env.REACT_APP_API_GATEWAY}${MANAGEMENT}purchases/persona/${prodId}`)
       
          }
          export function removePurchased(purchaseId){
        
            return axios.delete(`${process.env.REACT_APP_API_GATEWAY}${MANAGEMENT}purchases/remove/${purchaseId}`)
         
            }
            export function getReturned(prodId){
        
              return axios.get(`${process.env.REACT_APP_API_GATEWAY}${MANAGEMENT}purchases/persona/${prodId}/returned`)
           
              }
   
    export function getQuieres(perId){
        
      return axios.get(`${process.env.REACT_APP_API_GATEWAY}${MANAGEMENT}personas/queries?personaId=${perId}`)
   
      }


          export function getPersonalShop(page,id,searchKey){

            const headers={
                'x-tt-api-key':apiKey
            }
                        
                    if(searchKey&&page>1){
                       
                        headers.search_key=searchKey
                    }

                 
                return  axios.get(`${process.env.REACT_APP_API_GATEWAY}${MANAGEMENT}personas/shop?userId=${id}&page=${page}&limit=16` ,{headers})
             
                }



                export function getTagsFilters(Filters){

                  const headers={
                      'x-tt-api-key':apiKey
                  }
                  const body={}
                   
                  if(Filters){
                    let  filtersToSend={};

                    Object.keys(Filters)?.forEach(key => {
                      if(Filters[key]!=='All')
                          {
                              filtersToSend[key]=Filters[key]
                          }
                        })

                        body.selectedFilters=filtersToSend;
                  }
                  
                  
                
                      return  axios.post(`${process.env.REACT_APP_API_GATEWAY}${MANAGEMENT}products/filters`,body ,{headers })
                   
                      }
          
   export async function resetPersona(persona_id){

    try{
    const wishList=  await  getWishList(persona_id)
    const basket=  await  getBasket(persona_id)
    const purchased=  await  getPurchased(persona_id)
    const returned=  await  getReturned(persona_id)

    const newWishList= wishList.data.map((p)=>{
      p.wishList=true

      basket.data.map((p2)=>{
        if(p.product_id===p2.product_id)
        {
          p.basket=true
          return p;
        }
  
      })
      
      return p;

    })
    const newBasket= basket.data.map((p)=>{
      p.basket=true
      return p;

    })

    store.dispatch(resetWishList(newWishList))
    store.dispatch(resetBasket(newBasket))
    store.dispatch(resetPurchased(purchased.data))
    store.dispatch(resetReturned(returned.data))

    }
    catch(err){
      console.log(err)
    }
  
  }
 
 export async function getAllCountries() {
    try {
        const response = await fetch(`http://api.geonames.org/countryInfoJSON?username=TTdemo`);
        const data = await response.json();
        const countries = data.geonames.map(country => ({
            name: country.countryName,
            code: country.countryCode
        }));
        return countries;
    } catch (error) {
        console.error('Error fetching countries:', error);
        return [];
    }
}
export async function getCities(countryCode) {
  try {
      const response = await fetch(`http://api.geonames.org/searchJSON?country=${countryCode}&maxRows=100&username=TTdemo`);
      const data = await response.json();
      const cities = data.geonames.map(city => city.name);
      return cities;
  } catch (error) {
      console.error('Error fetching cities:', error);
      return [];
  }
}


