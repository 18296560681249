import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Router, Routes, useLocation } from 'react-router-dom';
import Similar from './pages/Similar';
import AutoTagging from './pages/AutoTagging';
import TextSearch from './pages/TextSearch';
import ImageSearch from './pages/ImageSearch';
import IntroPage from './pages/introPage/IntroPage';
import Protected from './component/unused/protectedRoute';
import { useEffect, useRef, useState } from 'react'
import Maintance from './component/Maintance';
import { createUser, getFilters, getTagsFilters } from './services/clientManagementService';
import {  SETFILTERS } from './config';
import { useDispatch, useSelector } from 'react-redux';
import { setFilters } from './slices/filtersSlice';
import PersonalShop from './pages/PersonalShop/PersonalShop';
import { CircularProgress } from '@mui/material';


function App() {

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const regex =/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  const [value, setValue] = useState('');


  const filters = useSelector((state) => state.filters.filters)
  const dispatch = useDispatch()
    let called=useRef(false)
    useEffect(()=>{
      let isAuth = localStorage.getItem('isAuthenticated')

      setIsLoggedIn(isAuth)
     let emailModalId= document.getElementById('emailModalId')
      let email = localStorage.getItem('email')
  
      if(!email&&emailModalId){
        document.getElementById('emailModalId')?.click()
      }
      else{

        if(email&&!called.current)
        {
      
          called.current=true;
          createUser(email);
        }
       

      }
    },[isLoggedIn])


    

   async function getAllFilters() {

    try{

      const sorted=['women', 'men', 'kids', 'other'];
      let website = await getFilters('websites')
      website = website.data.websites.map(item => item._id);  
     
   
      let newDepartments = await getFilters('departments')
      newDepartments = newDepartments.data.deps.map(item => item._id.toLowerCase()); 
      newDepartments.sort();
   const department = newDepartments.filter((dep)=> dep)
  
      let target_audience = await getFilters('target_audience',"trendyol")
      target_audience = target_audience.data.terget_audience.map(item => item._id); 
      target_audience.sort((a, b) => sorted.indexOf(a) - sorted.indexOf(b))
  
       const filters={website,target_audience,department};

    const tagsfilter=(await getTagsFilters()).data;

  tagsfilter.forEach((tag)=>{
 
      filters[tag.filter]=tag.values
    
  })
    
      dispatch(setFilters(filters))
   
       //to use filters outside component in any service
      SETFILTERS(filters);
  
      localStorage.setItem('filters',JSON.stringify(filters))
     
    }
    catch(error){
        console.log(error)
    }
   

    }

useEffect(()=>{

   getAllFilters()

},[])


  return (

 <>
      <button type="button" hidden id="emailModalId" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
 </button>
 <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div className="modal-dialog mt-5 py-5">
    <div className="modal-content p-3">
      <div className="modal-header border-0">
        <div className='row'> 
        <figure className='col-2'>
        <img src='../TT.svg' alt='TT'/>
        </figure>
        <div className='col-10 text-start'>
        <h1  className=" fs-5" id="staticBackdropLabel">Welcome to TwentyToo</h1>
        <p   id="staticBackdropLabel">Enter your email to start the experience</p>
        </div>
      
       </div>
       
      </div>
      <div className="modal-body ">
       <input placeholder='Email' name='email' value={value} onChange={(e)=>{setValue(e.target.value)}}  className="form-control rounded py-2 w-75 mx-auto"/>
       {/* <input placeholder='Age' name='age' value={value.age} onChange={(e)=>{onChangeForm(e)}}  className="form-control rounded py-2 w-75 mx-auto my-3"/>
       <input placeholder='Gender' name='gender' value={value.gender} onChange={(e)=>{onChangeForm(e)}}  className="form-control rounded py-2 w-75 mx-auto my-3"/>
       <input placeholder='Country' name='country' value={value.country} onChange={(e)=>{onChangeForm(e)}}  className="form-control rounded py-2 w-75 mx-auto"/>
       <input placeholder='City' name='city' value={value.city} onChange={(e)=>{onChangeForm(e)}}  className="form-control rounded py-2 w-75 mx-auto my-3"/>
       <input placeholder='Region' name='region' value={value.region} onChange={(e)=>{onChangeForm(e)}}  className="form-control rounded py-2 w-75 mx-auto"/>
       */}</div>
      <div className="modal-footer border-0 pb-4">
        <button  disabled={!value.toLowerCase().match(regex)} onClick={()=>{
        
          createUser(value);
          document.getElementById('emailModalId').click()
        }} className='btn btn-dark w-75 rounded-3 mx-auto py-2 bg-black'>Get Started</button>
      </div>
    </div>
  </div>
</div>
    {filters.website.length>0?<div className="App">

    { false?
    <Maintance/>
     :
    <BrowserRouter>
    <Routes>
    {/* <Route path='/' element={<BaseLayout/>}></Route> */}

          <Route
              path='/' 
              element={
              // <Protected isLoggedIn={isLoggedIn}>
                <>
                <IntroPage />
                </>

              //  </Protected>
              }
            >
          </Route>

          <Route
              path='/similar/:product_Id' 
              element={
              // <Protected isLoggedIn={isLoggedIn}>
              <>
                <Similar />
                </>
              // {/* </Protected> */}
              }
            >
          </Route>
          

          <Route
              path='/similar' 
              element={
              // <Protected isLoggedIn={isLoggedIn}>
              <>
                <Similar />
                </>
              // {/* </Protected> */}
              }
            >
          </Route>
          <Route
              path='/auto-tagging' 
              element={
              // <Protected isLoggedIn={isLoggedIn}>
                <AutoTagging />
              //  </Protected>
            }
            >
          </Route>

          <Route
              path='/text-search' 
              element={
              //  <Protected isLoggedIn={isLoggedIn}>
                <TextSearch />
                
              //  </Protected>

            }
            
            >
              
              
          </Route>
          <Route
              path='/personal-shop' 
              element={
              //  <Protected isLoggedIn={isLoggedIn}>
                <PersonalShop />
                
              //  </Protected>

            }
            
            >
              
              
          </Route>
          <Route
              path='/personal-shop/:product_Id' 
              element={
              // <Protected isLoggedIn={isLoggedIn}>
              <>
                <PersonalShop />
                </>
              // {/* </Protected> */}
              }
            >
          </Route>
          <Route
              path='/text-search/:product_Id' 
              element={
              //  <Protected isLoggedIn={isLoggedIn}>
                <TextSearch />
                
              //  </Protected>

            }
            >
              
          </Route>
         
          <Route
              path='/image-search' 
              element={
              //  <Protected isLoggedIn={isLoggedIn}>
                <ImageSearch />
              //  </Protected>
              }
            >
          </Route>

          {/* <Route
              path='/similar-details/:product_Id' 
              element={
              // <Protected isLoggedIn={isLoggedIn}>
                <SimilarDetailsComponent />
              //  </Protected>
            }
            >
          </Route> */}



          

      {/* <Route path='/similar' element={<Similar/>}></Route> */}
      {/* <Route path='/auto-tagging' element={<AutoTagging/>}></Route> */}
      {/* <Route path='/text-search' element={<TextSearch/>}></Route> */}
      {/* <Route path='/image-search' element={<ImageSearch/>}></Route> */}
      {/* <Route path='/similar-details/:id/:pageIndex/:hashKey/:websitee' element={<SimilarDetailsComponent/>}></Route> */}
      {/* <Route path='/similar-details/:id/:pageIndex' element={<SimilarDetailsComponent/>}></Route> */}
    </Routes>
    </BrowserRouter>}
    </div>:<div className='d-flex vh-100 justify-content-center align-items-center'><CircularProgress size={70} color='success' thickness={5} /></div> }
    </>
  );
}

export default App;
