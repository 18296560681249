import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
 
   export function sendMessageSQS(body){
    
  // const user= JSON.parse( localStorage.getItem('user'))
  //   body.user_id=user._id;
  //   body.email=user.email;
  //   body.country=user.country;
  //   body.city=user.city;
  //   body.region=user.region;
  //   body.age=user.age;
  //   body.gender=user.gender;
  body.id=uuidv4();

   return axios.post(`${process.env.REACT_APP_API_GATEWAY}v1/message` , { MessageBody:body,MessageGroupId:'persona-events' })
   
  
  
  };