import React, { useEffect, useState } from 'react'
import './personalization.css'
import CardText from '../../ui-components/Card';
import { ulid } from "ulid";
import CardBasket from '../../ui-components/CardBasket';
import { sendMessageSQS } from './../../sqs-config';
import { useSelector } from 'react-redux';
import { store } from '../../store';
import { removeBasket, resetBasket, resetPurchased, resetReturned, resetWishList, setBasket, setPurchased, setWishList } from '../../slices/shopSlice';
import { addPersona, setIsPersonalized, setPersona, setPersonas, setRegistred } from '../../slices/userSlice';
import { EditPersona, createPersona, getAllCountries, getBasket, getCities, getPersonas, getPurchased, getWishList, resetPersona } from '../../services/clientManagementService';

function Personalization() {
  const shop = useSelector((state) => state.shop)
  const user = useSelector((state) => state.user)
  const isPersonalized = useSelector((state) => state.user.isPersonalized)
  const [toggle, setToggle] = useState(isPersonalized)


  async function switchPersonlization() {

    setToggle(!toggle)

    if (user.registred && !isPersonalized) {
      await resetPersona(user.persona._id)

    }
    else {

    }

    store.dispatch(setIsPersonalized(!isPersonalized))


  }

  async function changePersona(e) {
    const persona = JSON.parse(e.target.value)
    await resetPersona(persona._id)
    store.dispatch(setPersona(persona))
  }

  useEffect(() => {
    const personalization = localStorage.getItem('personalization')

    if (personalization == 'true') {
      console.log(personalization)
      localStorage.removeItem('personalization')


      const dd = document.getElementById('openPersolizationModal').click()
      console.log(dd)
    }

  }, [])

  return (
    <main className={'row p-2  rounded-1 justify-content-between ' + (isPersonalized ? '' : 'py-3')} style={main}>

      <div className='d-flex  align-items-center justify-content-start col-8'>

        <label className="switch d-block ">
          <input onChange={switchPersonlization} checked={toggle} type="checkbox" />
          <span className="slider round"></span>
        </label>
        <button hidden={true} id='openPersolizationModal' data-bs-toggle="modal" data-bs-target="#PersonalizationModal"></button>
        <div className=' ps-2 row align-items-center '>
          {isPersonalized ? <p className=' fs-6 fw-bold text-start py-0 my-0  me-3 pointer col-3 text-decoration-underline' data-bs-toggle="modal" data-bs-target="#PersonalizationModal">
            Personlize
          </p>
            : <p className=' fs-6 fw-bold text-start py-0 my-0  col-3 ' >
              Personlize
            </p>}

          {isPersonalized ? <div className='col'>
            {user.registred ? <select className="form-select  " onChange={(e) => { changePersona(e) }} aria-label="Default select example">
              {user.personas.map((persona) => {
                return <option className='py-4' key={persona._id} value={JSON.stringify(persona)} selected={user.persona._id === persona._id}> {persona.username} </option>

              })}

            </select> :
              <p className='border ms-2 p-2 bg-white my-auto rounded-3'>Unregistered </p>}
          </div> : <p className='my-0 py-0  text-start'>
            Enable me to start a personalized shopping experience!
          </p>}





        </div>

      </div>

      {isPersonalized && <div className=' col-3 text-end  pt-1 row '>

        <div className=' label  col-4' data-bs-toggle="offcanvas" data-bs-target="#wishListCanvas" aria-controls="offcanvasWithBothOptions">
          <figure className='pt-3'>
            <img src='/fav.svg' alt="fav" />
            <label className='green-cirlce'>{shop.wishList.length}</label>
          </figure>
          <p>Wishlist</p>
        </div>
        <div className=' label  col-4' data-bs-toggle="offcanvas" data-bs-target="#BasketOffCanvas" aria-controls="offcanvasWithBothOptions">
          <figure className='pt-3'>
            <img src='/basket.svg' alt="basket" />
            <label className='green-cirlce'>{shop.basket.length}</label>
          </figure>
          <p>Basket</p>
        </div>
        <div className=' label  col-4' data-bs-toggle="offcanvas" data-bs-target="#PurchasedOffCanvas" aria-controls="offcanvasWithBothOptions">
          <figure className='pt-3'>
            <img src='/purchased.svg' alt="purchased" />
            <label className='green-cirlce'>{shop.purchased.length}</label>
          </figure>
          <p>Purchased</p>
        </div>


      </div>}


      <PersonalizationModal setToggle={setToggle} />

      <WishListOffCanvas />
      <BasketOffCanvas />
      <PurchasedOffCanvas />
    </main>
  )
}

export default Personalization


const main = {
  backgroundColor: "#F3F3F3"
}




function PersonalizationModal(props) {

  const user = useSelector((state) => state.user)
  const [selectPersona, setSelectedPersona] = useState(user.persona)

  useEffect(() => {

    if (user.registred) {
      setSelectedPersona(user.personas[0])
    }
  }, [user.registred])

  useEffect(() => {

    if (user.registred) {
      setSelectedPersona(user.persona)
    }

  }, [user.persona])



  async function startPersonalization() {

    if (user.registred) {

      await resetPersona(selectPersona._id)
      store.dispatch(setPersona(selectPersona))
      store.dispatch(setIsPersonalized(true))
      props.setToggle(true)
    }
    else {

      store.dispatch(setPersona({
        _id: ulid()
      }))

      store.dispatch(resetReturned([]))
      store.dispatch(resetBasket([]))
      store.dispatch(resetPurchased([]))
      store.dispatch(resetWishList([]))
      store.dispatch(resetReturned([]))

    }
  }

  return (
    <>

      <div className="modal fade" id="PersonalizationModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content px-5 py-3 container">
            <div className="modal-header  border-0">
              <h1 className="modal-title  fs-5 mx-auto" id="exampleModalLabel">Personalization</h1>
            </div>
            <div className="modal-body  ">


              <p className='text-start mt-4 fs-6'>Choose the type of persona to test with </p>
              <div className='d-flex justify-content-between '>
                <button type="button" onClick={() => store.dispatch(setRegistred(true))} className={"btn border border-1 rounded-3  py-3 " + (user.registred ? 'border-black' : '')} >Registered User <img src={user.registred ? '/selected-circle.svg' : '/unselected-circle.svg'} width={22} height={22} alt='circel' /></button>
                <button type="button" onClick={() => store.dispatch(setRegistred(false))} className={"btn border border-1 rounded-3  py-3 " + (!user.registred ? 'border-black' : '')} > Unregistered User  <img src={!user.registred ? '/selected-circle.svg' : '/unselected-circle.svg'} width={22} height={22} alt='circel' /></button>
              </div>
              {user.registred ? <>
                <div className='d-flex justify-content-between text-black mt-4 '>
                  <p className='text-start fs-6'>Persona </p>
                  <p className='text-start  pointer' data-bs-toggle="modal" data-bs-target="#AddPersonaModal" onClick={() => { setSelectedPersona(null) }} ><span className='fw-bold'>+</span> Add new persona </p>
                </div>
                {user.personas.map((persona, index) => {

                  return (
                    <button key={persona?._id} type="button" onClick={() => setSelectedPersona(persona)} className={"btn border border-1 rounded-3 w-100 my-2 d-flex  pt-2 " + (selectPersona?._id === persona?._id ? 'border-black' : '')} >
                      <div className='text-start col-1'>  <img src={selectPersona?._id === persona?._id ? '/selected-circle.svg' : '/unselected-circle.svg'} width={22} height={22} alt='circel' />

                      </div>
                      <p className=' ps-2 col-10 text-start '>
                        {persona?.username}
                        <p style={{ fontSize: '14px', color: '#383838' }}>
                          <span>{persona?.description}</span>
                          <br />
                          <span>{persona?.gender?persona?.gender+', ' :""}{persona?.age?persona?.age+'Y, ' :""} {persona?.country?persona?.country+', ' :""}{persona?.city?persona?.city+', ' :""}{persona?.region?persona?.region+', ' :""}</span>


                        </p>

                      </p>
                      <p className='col-1' data-bs-toggle="modal" data-bs-target="#AddPersonaModal">Edit</p>
                    </button>

                  )

                })

                }
              </> :
                <p className=' mt-5 '> No history is saved for an unregistered user’s events. </p>}
            </div>
            <div className="modal-footer border-0">

              <button disabled={!selectPersona} onClick={startPersonalization} type="button" data-bs-dismiss="modal" className="btn btn-dark rounded-3 bg-black w-100 py-2">Start</button>
            </div>
          </div>
        </div>
      </div>
      <AddPersonaModal persona={selectPersona} />

    </>


  )
}

function AddPersonaModal(props) {
  const [countries, setCountries] = useState([])

  const [cities, setCities] = useState([])

  useEffect(() => {
    getAllCountries()
      .then(countries => {
        setCountries(countries)

      })
      .catch(error => {
        console.error('Error:', error);
      });




  }, [])


  const [value, setValue] = useState({
    username: '',
    description: '',
    gender: '',
    age: '',
    country: '',
    city: '',
    region: ''
  });

  useEffect(() => {
    if (props.persona) {
      setValue({ ...props.persona })
    }
    else {

      setValue({
        username: '',
        description: '',
        gender: '',
        age: '',
        country: '',
        city: '',
        region: ''

      })
    }



  }, [props.persona])
  function onChangeForm(e) {
    let eName = e.target.name;
    let eValue = e.target.value;
    setValue(prevState => ({
      ...prevState,
      [eName]: eValue
    })
    );


    if (eName === 'country') {
      console.log(eValue)
      const country = countries.find((country) => country.name === eValue)

      getCities(country.code)
        .then(cities => {
          setCities(cities)
        })
        .catch(error => {
          console.error('Error:', error);
        });
    }



  }

  function createNewPersona() {

    if (props.persona)  {

      EditPersona({username:value.username,
        description:value.description,
        gender:value.gender,
        age:value.age,
        country:value.country,
        city:value.city,
        region:value.region},value._id).then((res) => {
          const userId=localStorage.getItem('user_id')

          getPersonas(userId).then( (res)=>{
    
            setValue({
              username: '',
              description: '',
              gender: '',
              age: '',
              country: '',
              city: '',
              region: '',
      
            });
         
              store.dispatch(setPersonas(res.data.data))
      
          
      
          })
       
  
      }).catch((err) => {
        console.log(err)
  
  
      })
    }else
    {
      
      createPersona(value).then((res) => {
  
        store.dispatch(addPersona(res.data.data))
        setValue({
          username: '',
          description: '',
          gender: '',
          age: '',
          country: '',
          city: '',
          region: '',
  
        });
  
      }).catch((err) => {
        console.log(err)
  
  
      })
  
      }

  }
  return (
    <>

      <div className="modal fade" id="AddPersonaModal" tabIndex="-1" aria-labelledby="AddPersonaModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content px-5 py-3 container">
            <div className="modal-header  border-0">
              <img className='pointer' data-bs-toggle="modal" data-bs-target="#PersonalizationModal" src='/curved-back.svg' alt="back" />
              <h1 className="modal-title  fs-5 mx-auto" >{props.persona ? "Edit Persona" : "Add New Persona"}</h1>
            </div>
            <div className="modal-body">

              <div className="mb-2 text-start">
                <label for="exampleFormControlInput1" className="form-label ">Persona Name</label>
                <input name='username' value={value.username} onChange={(e) => { onChangeForm(e) }} style={{ backgroundColor: "#F3F3F1" }} className="form-control border-0 border-none" id="exampleFormControlInput1" placeholder="Persona Name" />
              </div>
              <div className="mb-2 text-start">
                <label for="exampleFormControlInput1" className="form-label ">Description</label>
                <input name='description' value={value.description} onChange={(e) => { onChangeForm(e) }} type="text" style={{ backgroundColor: "#F3F3F1" }} className="form-control border-0" id="exampleFormControlInput1" placeholder="describe your persona in a line" />
              </div>
              <div className="mb-2 text-start">
                <label for="exampleFormControlInput1" className="form-label ">Country</label>
                <select style={{ backgroundColor: "#F3F3F1" }} className="form-select border-0 overflow-hidden" name='country' value={value.country} onChange={(e) => { onChangeForm(e) }}>
                  <option disabled style={{ fontFamily: "Space Grotesk", width: '50px' }} selected={true} value='' > Select a Country  </option>
                  {countries.map((el, index) => {
                    return (<option key={el + index} style={{ fontFamily: "Space Grotesk", width: '50px' }} >{el.name}</option>)
                  })}
                </select>
              </div>
              <div className="mb-2 text-start">
                <label for="exampleFormControlInput1" className="form-label ">City</label>
                <select style={{ backgroundColor: "#F3F3F1" }} className="form-select border-0 overflow-hidden" name='city' value={value.city} onChange={(e) => { onChangeForm(e) }}>
                  <option disabled style={{ fontFamily: "Space Grotesk", width: '50px' }} selected={true} value='' > Select a City   </option>
                  {cities.map((el, index) => {
                    return (<option key={el + index} style={{ fontFamily: "Space Grotesk", width: '50px' }} >{el}</option>)
                  })}
                </select>
              </div>
              <div className="mb-2 text-start">
                <label for="exampleFormControlInput1" className="form-label ">Age</label>
                <input type="text" name='age' value={value.age} onChange={(e) => { onChangeForm(e) }} style={{ backgroundColor: "#F3F3F1" }} className="form-control border-0" id="exampleFormControlInput1" placeholder="Age" />
              </div>
              <div className="mb-2 text-start">
                <label for="exampleFormControlInput1" className="form-label ">Gender</label>
                <input type="text" name='gender' value={value.gender} onChange={(e) => { onChangeForm(e) }} style={{ backgroundColor: "#F3F3F1" }} className="form-control border-0" id="exampleFormControlInput1" placeholder="Gender" />
              </div>

              <div className="mb-2 text-start">
                <label for="exampleFormControlInput1" className="form-label ">Neighborhood</label>
                <input name='region' value={value.region} onChange={(e) => { onChangeForm(e) }} type="text" style={{ backgroundColor: "#F3F3F1" }} className="form-control border-0" id="exampleFormControlInput1" placeholder="Neighborhood" />
              </div>


            </div>
            <div className="modal-footer border-0">

              <button disabled={value.username === "" || value.description === ""} onClick={createNewPersona} data-bs-toggle="modal" data-bs-target="#PersonalizationModal" type="button" className="btn btn-dark rounded-3 bg-black w-100 py-2">Save</button>
            </div>
          </div>
        </div>
      </div>


    </>


  )
}


function WishListOffCanvas() {
  const shop = useSelector((state) => state.shop)
  const [products, setProducts] = useState(shop.wishList)


  useEffect(() => {

    const newProducts = products.map((product) => {
      let isBasket = false;

      let index = shop.basket.findIndex((p) => p.product_id === product.product_id)

      if (index !== -1) {
        isBasket = true
      }
      return { ...product, basket: isBasket }

    })
    setProducts(newProducts)

  }, [shop.basket])

  useEffect(() => {
    setProducts(shop.wishList)
  }, [shop.wishList])
  return (

    <div className="offcanvas offcanvas-end px-0" style={{ width: "40%" }} data-bs-scroll="false" tabindex="-1" id="wishListCanvas" aria-labelledby="offcanvasWithBothOptionsLabel">
      <div className=" d-flex justify-content-between mt-4 px-3 ">
        <h5 className="offcanvas-title ms-2" id="offcanvasWithBothOptionsLabel">Wishlist</h5>
        <button type="button" className="btn " data-bs-dismiss="offcanvas" aria-label="Close"><img src='/close.svg' width={30} height={30} alt='close' /></button>
      </div>
      <div className='w-100  my-1' style={{ height: "1px", backgroundColor: '#DCE0DE' }} ></div>
      <div className="offcanvas-body ">
        <div className='row   '>

          {products.map((item, index) => {

            return (
              <div key={item.product_id} className='col-6  p-3 '>
                <CardText p={item} />
              </div>
            )

          })}

        </div>
      </div>
    </div>
  )
}



function BasketOffCanvas() {
  const products = useSelector((state) => state.shop.basket)
  const user = useSelector((state) => state.user)
  function purchased() {

    products.forEach(p => {

      store.dispatch(setPurchased(p))
      store.dispatch(removeBasket(p))
      sendMessageSQS(
        {
          weight: 3,
          event_type: 'purchased',
          product_id: p.product_id,
          persona_id: user.persona._id,
          user_id: user.persona.user,
          age: user.persona.age,
          gender: user.persona.gender,
          country: user.persona.country,
          city: user.persona.city,
          region: user.persona.region
        }
      ).then((res) => {


      }).catch((err) => { console.log(err) })


    });

  }

  return (

    <div className="offcanvas offcanvas-end px-0" style={{ width: "35%" }} data-bs-scroll="false" tabindex="-1" id="BasketOffCanvas" aria-labelledby="offcanvasWithBothOptionsLabel">
      <div className=" d-flex justify-content-between mt-4 px-3 ">
        <h5 className="offcanvas-title ms-2" id="offcanvasWithBothOptionsLabel">Basket <span className='fs-6 ' > ({products.length} items)</span> </h5>
        <button type="button" className="btn " data-bs-dismiss="offcanvas" aria-label="Close"><img src='/close.svg' width={30} height={30} alt='close' /></button>
      </div>
      <div className='w-100  my-1' style={{ height: "1px", backgroundColor: '#DCE0DE' }} ></div>
      <div className="offcanvas-body  ">
        {products.length === 0 ?
          <h5 className='mt-5 pt-5'>
            Cart is Empty
          </h5>
          :
          <>
            <div className=''>

              {products.map((item, index) => {
                return (
                  <div key={item.product_id + index} className=' '>
                    <CardBasket p={item} type={"basket"} />
                    {(index + 1) !== products.length && <div className='w-100  my-4' style={{ height: "1px", backgroundColor: '#E7E8E8' }} ></div>}
                  </div>
                )

              })}

            </div>
            <button onClick={purchased} type="button" className="btn btn-dark my-5 rounded-3 bg-black w-100 py-2">   <img src='/purchaseWhite.svg' alt="Purchase" />   Purchase</button>
          </>}
      </div>
    </div>
  )
}

function PurchasedOffCanvas() {

  const purchased = useSelector((state) => state.shop.purchased)
  const returned = useSelector((state) => state.shop.returned)


  const [selected, setSelceted] = useState('purchased')


  return (

    <div className="offcanvas offcanvas-end px-0" style={{ width: "35%" }} data-bs-scroll="false" tabindex="-1" id="PurchasedOffCanvas" aria-labelledby="offcanvasWithBothOptionsLabel">
      <div className=" d-flex justify-content-between mt-4 px-3 ">
        <h5 className="offcanvas-title ms-2" id="offcanvasWithBothOptionsLabel"> {selected === 'purchased' ? 'Purchased' : 'Returned'} <span className='fs-6 ' > ({selected === 'purchased' ? purchased.length : returned.length} items)</span> </h5>
        <button type="button" className="btn " data-bs-dismiss="offcanvas" aria-label="Close"><img src='/close.svg' width={30} height={30} alt='close' /></button>
      </div>


      <div className='w-100  my-1' style={{ height: "1px", backgroundColor: '#DCE0DE' }} ></div>
      <div style={{ backgroundColor: '#F3F3F3' }} className=' row justify-content-evenly mt-2 p-1 col-11 mx-auto rounded-3' >

        <button onClick={() => { setSelceted('purchased') }} className={'btn  py-2 col-6 rounded-3 border-0 ' + (selected === 'purchased' ? 'bg-white' : '')}>Purchase History </button>
        <button onClick={() => { setSelceted('returned') }} className={'btn  py-2 col-6 rounded-3 border-0 ' + (selected === 'returned' ? 'bg-white' : '')} >Returned</button>
      </div>
      <div className="offcanvas-body  ">


        <div className=''>

          {(selected === 'purchased' ? purchased : returned).map((item, index) => {
            return (
              <div key={item.product_id + index} className=' '>
                <CardBasket p={item} type={selected} />
                {<div className='w-100  my-4' style={{ height: "1px", backgroundColor: '#E7E8E8' }} ></div>}
              </div>
            )

          })}

        </div>

      </div>
    </div>
  )
}