import React from 'react'
import { IMAGEURL } from '../config';
import { store } from '../store';
import { removeBasket, removePurchased, setReturned } from '../slices/shopSlice';
import { sendMessageSQS } from '../sqs-config';
import { useSelector } from 'react-redux';

function CardBasket({ p, type }) {
  const user=useSelector((state)=> state.user)
  const options = {
    weekday: "short",
    month: "short",
    day: "2-digit",
    year: "numeric"
  };
  // const dateObj = new Date(p?.date ?? p?.last_edit ??p?.createdAt??'');

function deleteFromBasket(){
  store.dispatch(removeBasket(p))
  sendMessageSQS(
    {
      weight:1,
      event_type:"remove",
      product_id:p.product_id,
      persona_id:user.persona._id,
       user_id: user.persona.user,
        age: user.persona.age,
          gender: user.persona.gender,
          country: user.persona.country,
          city: user.persona.city,
          region: user.persona.region
    }
    ).then((res)=>{

      
    }).catch((err)=>{console.log(err)})

 
}



function deleteFromPurchased(){
  store.dispatch(removePurchased(p))
  store.dispatch(setReturned(p))
   removePurchased(p.product_id)
  sendMessageSQS(
    {
      weight:1,
      event_type:"return",
      product_id:p.product_id,
      persona_id:user.persona._id,
          user_id: user.persona.user,
          age: user.persona.age,
          gender: user.persona.gender,
          country: user.persona.country,
          city: user.persona.city,
          region: user.persona.region
    }
    ).then((res)=>{
     
    }).catch((err)=>{console.log(err)})

 
}
  return (
    <>
      <div className='row' >
        <div className="card-Basket col-4" >

          <img className="w-100 h-100 rounded-2" src={`${IMAGEURL}${p?.product_id}_0.jpg`} alt=""   ></img>

        </div>

        <div className={type === 'purchased' ?'col-5':'col-7'}>
          <p className="py-0 text-start my-0 text-over-fLow" > {p?.title ?? ''}</p>
          <div className=" text-start text-over-fLow">
            <span className="rounded-3 px-1  pb-1" style={{ color: "#FF01A0", fontSize: "12px", background: "#F3F3F3" }} ><img src="/similarity.svg" alt="s" /> {Number.parseInt((p?.score ?? 1) * 100)}%</span>
            {/* <span className="text-secondary " style={{ fontSize: "12px" }} > { dateObj?Intl.DateTimeFormat("en-US", options)?.format(dateObj):''}</span> */}
          </div>
        </div>


        {type === 'purchased' ? <div className='col-2' >

          <button onClick={deleteFromPurchased} className='btn border border-1 d-flex align-items-center  '><img className='mt-1 me-1' style={{ height: '15px', width: '15px' }} src='/return.svg' alt='return'  ></img> return </button>
        </div>
          : <div className='col-1' >
           <>
          { type!=='returned' && <img onClick={deleteFromBasket} style={{ height: '25px', width: '25px' }} src='/trash.svg' alt='trash'  ></img>
           } </>
          </div>}


      </div>

    </>

  )
}

export default CardBasket

