import { useEffect, useState } from "react";

import { Link, useLocation } from 'react-router-dom';
import { getAllProductsCount, getTagsFilters } from "../services/clientManagementService";
import "../baseLayout.css"
import FilterItem from "./FilterItem";
import { useDispatch, useSelector } from "react-redux";
import { SETFILTERS } from "../config";
import { setFilters } from "../slices/filtersSlice";
export default function SideBarComponent(props) {
    const filters = useSelector((state) => state.filters.filters)
    const location = useLocation();
    const dispatch=useDispatch()
    // let [webVal , setWebVal] = useState()
    let [itemCount, setitemCount] = useState(null)


    useEffect(() => {

        getAllProductsCount().then((res) => {

            setitemCount(res.data.count)

        }).catch((e) => {
            console.log(e);
        })

    }, [])

    async function resetFilters() {

        for (const key in props.selectedFilters) {
            props.selectedFilters[key] = 'All'
        }

        props.setSelectedFilters({ ...props.selectedFilters })
  
          
       const filters= JSON.parse( localStorage.getItem('filters'))

       dispatch(setFilters(filters))
       //to use filters outside components in any service
        SETFILTERS(filters)


        

    }


    return (
        <>
            <div className=" col-lg-2 col-12  vh-100 mx-md-auto mx-lg-0 sideBar  border overflow-y-auto  ">
                <div className="row">
                    <div className="d-flex ">
                        <div className="d-flex align-self-center ">
                            <Link to="/" ><img src="/back.png" width="25px" height="25px" alt="back"></img></Link>
                        </div>
                        <div className="d-flex">
                            <img src="/TT demo.svg" width="180px" height="100px" alt="demo"></img>
                        </div>
                    </div>
                </div>
                {
                    location.pathname !== '/auto-tagging' && itemCount && <p className="text-start" style={{ color: "#888888" }}>Total items: {itemCount?.toLocaleString('en-US')}</p>
                }

                {location.pathname === '/auto-tagging' ? <></> :
                    <div className="row ">
                        <FilterItem selectedFilters={props.selectedFilters} setSelectedFilters={props.setSelectedFilters} name={'website'} label={"Websites"}   ></FilterItem>
                    </div>
                }


                {location.pathname === '/auto-tagging' ? <></> :
                    <div className="row">
                        <FilterItem selectedFilters={props.selectedFilters} setSelectedFilters={props.setSelectedFilters} name={'target_audience'} label={"Target Audience"}   ></FilterItem>

                    </div>
                }


                {location.pathname.includes('similar') || location.pathname.includes('text-search') ?
                    <FilterItem selectedFilters={props.selectedFilters} setSelectedFilters={props.setSelectedFilters} name={'department'} label={"Departments"}    ></FilterItem>

                    :
                    <></>


                }

                {location.pathname.includes('similar') &&
                    <>
                        {Object.keys(filters).map((filter, index) => {

                            if (filter !== 'website' && filter !== 'department' && filter !== 'target_audience') {
                                return <FilterItem key={index} selectedFilters={props.selectedFilters} setSelectedFilters={props.setSelectedFilters} name={filter} label={filter}    ></FilterItem>

                            }

                        })}
                    </>}
                {!location.pathname.includes('auto-tagging') &&
                    <div className="d-flex justify-content-evenly mt-3">
                        <button type="button" onClick={() => { props.setFetch(prev => !prev) }} className="btn btn-dark rounded-3 bg-black col-6 py-2">Apply</button>
                        <button type="button" onClick={resetFilters} className="btn btn-dark rounded-3 bg-black col-5 py-2">Reset</button>
                    </div>
                }
                <img className="mt-5" style={{ width: "95%" }} src="/warning.svg" />

            </div>

        </>

    )
}

let verticalLine = {
    "borderLeft": "1px solid #E6EDFF",
    "height": "800px",
    "marginTop": "25px",
    "width": "20px"
}