import { useRef } from "react";


export default function NewFileUploader({setImage ,type}){
    const fileInputRef = useRef(null);
  
    
      const readFileAsDataURL = (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
      
          reader.onload = function (event) {
            const base64Image = event.target.result;
            resolve(base64Image);
          };
      
          reader.onerror = function (error) {
            reject(error);
          };
      
          reader.readAsDataURL(file);
        });
      };
    
     let handleFileChange = async (event) => {

     if(event.target.files[0]){

     const baseImg = await readFileAsDataURL(event.target.files[0]);

     setImage(baseImg)

    }
           
     }

 
    function handleFileSelect(){
        fileInputRef.current.click();
    }


    return(
  <>
       {type==='shop'?
   
           <div className='row  container justify-content-center   '>
         <figure className='col-1 my-auto  text-end pointer'><img src='/image-search.svg'/></figure>
           <p className='col-5 my-auto'>Drag an image here or</p>
           <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange}/>
          <button className='btn btn-outline-dark col-4'  onClick={handleFileSelect}>Upload image</button>
       
           </div> 

       :
       <div className="row col-lg-4 col-md-4 col-sm-5 col-9  m-auto mt-2">
        
       <div className="d-flex border justify-content-between" onClick={handleFileSelect}>
           <div className="p-3" style={{fontFamily:"Signika"}}>Upload Image </div>
           <div className="p-3"> </div>

           <div className="p-2 hoverCamera ">
                   <img src="../photo_camera.png" width={40} height={40} alt="camera" ></img>
                   <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange}/>
           </div>
       </div>
   </div>
       } 
    </>
        
        
         
        
      
 
    
    );
}

