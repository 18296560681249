import React from 'react'
import '../baseLayout.css'
import bg from "../assets/TTLogo.svg"
import shape from "../assets/maintanceShape.svg"

function Maintance() {
  return (
    <div className='maintance_bg' > 
    <div className='w-100 text-start'>
      <img className='mt-4 ms-4' src={bg} alt="" />
    </div>

    <div className='row col-8 mt-5 py-5  mx-auto'>

    <div className='col-6 py-4 text-start'>
    <p  style={{fontSize:"46px",color:"#16D986"}}>We're upgrading to serve you better. </p>
    <p  style={{fontSize:"26px"}}>Please check back soon, it'll be worth the wait! </p>
    <p style={{fontSize:"16px",color:"#EB4335"}}>Error: Network error</p>
    <button className='btn btn-dark bg-black px-5'>Home</button>
    </div>
    <div className='col-6 text-start'>
      <img className='mt-4 ms-4' src={shape} alt="" />
    </div>
    </div>
    </div>
  )
}

export default Maintance