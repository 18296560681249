import { useEffect, useState } from "react";
import FileUploader from "../component/unused/FileUploader";
import SideBarComponent from "../ui-components/SideBarComponent";
import NewFileUploader from './../component/NewFileUplader';
import ErrorMessageComponent from "../component/ErrorMessageComponent";
import ProgressBarComponent from './../ui-components/ProgressBarComponent';
import { autoTag } from "../services/autoTagService";

export default function AutoTagging(){
    const [img , setImg] = useState()
    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState(false);
    const [lang , setLang] = useState("eng_tags")
    const [errorMsg , setErrorMsg] = useState()
   

    useEffect(()=>{
     if(img){
        getTags()
     }
       
    },[img])

    function getTags(){
        setLoading(true)
        setResult(null)
        autoTag(img).then((res)=>{
        
            setResult(res.data.message[0])
            setLoading(false)
        }).catch((err)=>{

            setErrorMsg(err.message)
            setLoading(false)
        })


    }

    return(
        
        <div className="container-fluid">
           { errorMsg ? <ErrorMessageComponent message={errorMsg} />:
            <div className="row ">
                <SideBarComponent></SideBarComponent>
            
                 <div className="col-2" ></div> 

                <div className="col-lg-9  mt-5">
   
                    <div className=" text-center  m-auto">
                        <p  className=" text-center"style={paragraphStyle}>AutoTag for Fashion</p>
                    </div>
            
                <div className="row mt-3  mx-auto">
                     <NewFileUploader setImage={setImg} />
                   </div>

                  { loading? <div className=" row ">
                            <div className=" col-lg-5 col-9   m-auto">
                                <div  style={fileUploaderStyle}>
                                    <img src={img} style={imageAfterUplaod} className="rounded" alt="img"></img>
                                </div>
                            </div>
                            <div className=" col-lg-7 col-10 m-auto  mt-5 my-md-auto">
                               
                                <ProgressBarComponent></ProgressBarComponent>
                            </div>
                        </div>
                        : 
                        <>
                     {result&&
                     <div className="row mt-4" dir={lang==="eng_tags"?'ltr':'rtl'}>
                         <div className="col-md-4 mx-auto  ">
                             <div className="mx-auto" style={fileUploaderStyle}>
                                 <img src={img} style={image} alt="img" ></img> 
                             </div> 
                         </div>
                         <div className="col-md-8">
                            
                             {/* switch en/ar lang */}

                       {result['ar_tags']&&<div className="row col-2 mx-auto mx-md-0  mt-5">
                                 <div className="btn-group"  role="group" aria-label="Language Toggle">
                                     <button type="button" className={"btn " +(lang==='eng_tags'? 'bg-dark text-white':' bg-light' )} id="englishBtn" style={btnStyleEn} onClick={()=>{setLang('eng_tags')}}>En</button>
                                     <button type="button" className={"btn " +(lang!=='eng_tags'? 'bg-dark text-white':' bg-light' )} id="arabicBtn" style={btnStyleAr} onClick={()=>{setLang('ar_tags')}}>Ar</button>
                                 </div>
                             </div>}
         
                                 <p className=" ms-2 mt-3   " style={{fontFamily:"Signika" , fontSize:"23px" ,textAlign: "start"  }} >{result[lang]["title"]??result[lang]["Title"]}</p>
                                 <p className=" ms-2 mt-3   " style={{fontFamily:"Signika" , fontSize:"16px" ,textAlign: "start" ,whiteSpace: "pre-wrap" }} >{ result[lang]["description"]}</p>
                             
                             {/* tag */}
                            
                                 <p className="mt-2  ms-4 ms-md-2  col-md-3 " style={{fontFamily:"Signika" , fontSize:"17px"  , lineHeight:"4px" , textAlign: "start"}} > {lang==='eng_tags'?'Tags':'العلامات'}</p>
                             <div className=" col-lg-9 mx-auto mx-lg-0 my-4 ">
                                
                         {Object.keys(result[lang]).map((tag)=>{
                             if(tag !== "title"&&tag !== "Title"&&tag !== "description"){
                                 return (
                                     <>
                                      <div className="row mx-auto mx-md-0  justify-content-start mt-1">
                                          <div className=" col-4" style={spanStyle}> {tag} :</div>
                                          <div className="col-5   "><span style={{"display":"block" ,"fontFamily": "Signika",  "fontSize": "15px",textAlign:'start'}} >{result[lang][tag]} </span></div>
                                      </div>
                                      </>
                                  )
                             }
                             
                          })}
                          </div>
                         </div>
                     </div>}
                     </>
                     }

            </div>
            </div>}
        </div>
    
      )
} 


let paragraphStyle ={
    fontFamily:"Signika",
    fontWeight:"600",
    "lineHeight":"25px",
    "fontSize":"23px",
   
}


let verticalLine ={
"borderLeft": "1px solid #E6EDFF",
"height": "800px",
"marginTop":"25px",
"width":"20px"
}
const v1PStyle = {
    "textAlign": "left",
    fontFamily: "Signika",
    fontWeight: "100",
    lineHeight: "1px"
}
const fileUploaderStyle = {
    "width": "240px",
    "height": "240px",
    "justifyContent": "center",
    "alignItems": "center",
    "margin": "auto",
    "cursor": "pointer",
    "position": "relative",
    "marginTop": "40px",
    "backgroundColor": "#F5F5F5",
    "borderRadius": "5px"
}


const imageAfterUplaod = {
    "width": "220px",
    "height": "220px",
    "zIndex": 1,
    "marginTop": "10px",
    "borderRadius": "5px",
    opacity: "0.5"
}



const image = {
    "width": "220px",
    "height": "220px",
    "zIndex": 1,
    "marginTop": "10px",
    "borderRadius": "5px",
   
}


let spanStyle = {
    "fontFamily": "Signika",
    "fontSize": "13px",
    "marginTop":"3px",
    textAlign: "start"
}

const btnStyleEn = {

"fontFamily": "Signika",
"borderTopRightRadius":"50%",
"borderBottomRightRadius":"50%"
}

const btnStyleAr = {

"fontFamily": "Signika",
"borderTopRightRadius":"50%",
"borderBottomRightRadius":"50%"

}