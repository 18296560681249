import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  personas:[],
  persona:{},
  isPersonalized:false,
  registred:true

}

export const userSlice = createSlice({
  name: 'user',
  
  initialState,
  reducers: {
   

    setUserData: (state, action) => {
    
      
      state.data=action.payload
        
    },
    setPersonas: (state, action) => {
    
      
    state.personas=action.payload
      
  },
  setIsPersonalized: (state, action) => {
    
      
    state.isPersonalized=action.payload
      
  },
  setRegistred: (state, action) => {
    
      
    state.registred=action.payload
      
  },
  setPersona: (state, action) => {
    
      
    state.persona=action.payload
      
  },
  
  addPersona: (state, action) => {
    
      
    state.personas.push(action.payload)
      
  },
  
   
  }
})

// Action creators are generated for each case reducer function
export const { addPersona,setPersonas,setPersona,setIsPersonalized ,setRegistred,setUserData} = userSlice.actions

export default userSlice.reducer