import { createSlice ,current} from '@reduxjs/toolkit'

const initialState = {
  products:[],
  wishList:[],
  basket:[],
  purchased:[],
  returned:[]

}

export const shopSlice = createSlice({
  name: 'shop',
  
  initialState,
  reducers: {
   

    setProducts: (state, action) => {
    
      
    state.products=action.payload
      
  },
  updateProducts: (state, action) => {
    
      const product=action.payload;
      const products= state.products;
      state.products= products.map((p)=>{

        if(p.product_id===product.product_id)
            p={...product}
        

      })
   
      
  },
    setWishList: (state, action) => {
    
      
      state.wishList.unshift(action.payload)
        
    },
    
    removeWishList: (state, action) => {

  const products= state.wishList
 state.wishList=products.filter((p)=> p.product_id !==action.payload.product_id)
  

    },

   

    setBasket: (state, action) => {
   
      state.basket.unshift(action.payload)
    },
    removeBasket: (state, action) => {

      const products= state.basket
     state.basket=products.filter((p)=> p.product_id !==action.payload.product_id)
      
    },

        removePurchased: (state, action) => {

          const products= state.purchased
           let index=  products.findIndex((p)=> p.product_id ===action.payload.product_id)
         products.splice(index,1)
         state.purchased= products
            },
            
    setPurchased: (state, action) => {
      state.purchased.unshift(action.payload)
    },

    setReturned: (state, action) => {
      state.returned.unshift(action.payload)
    },
    resetWishList: (state,action) => {
    
      
      state.wishList=action.payload
        
    },
    resetPurchased: (state,action) => {
    
      
      state.purchased=action.payload
        
    },
    resetBasket: (state,action) => {
    
      
      state.basket=action.payload
        
    },
    resetReturned: (state,action) => {
    
      
      state.returned=action.payload
        
    },
  },


 
})

// Action creators are generated for each case reducer function
export const { setWishList ,setPurchased,setBasket,removeWishList ,removeBasket,removePurchased,setReturned,resetReturned,resetBasket,resetPurchased,resetWishList} = shopSlice.actions

export default shopSlice.reducer