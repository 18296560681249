import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import ErrorMessageComponent from '../../component/ErrorMessageComponent'
import SideBarComponent from '../../ui-components/SideBarComponent'
import Personalization from '../../component/personalization/Personalization'
import { IMAGEURL, SOURCE, resetSource } from '../../config'
import ProgressBarComponent from '../../ui-components/ProgressBarComponent'
import {  Chip, Pagination } from '@mui/material'
import { textSeacrh, textSeacrhHistory } from '../../services/textSearchService'
import CardText from '../../ui-components/Card'
import styles from './PersonalShop.module.css'
import { findSimilars } from '../../services/similarService'
import NewFileUploader from '../../component/NewFileUplader'
import { imageSearch } from '../../services/imageSearchService'
import axios from 'axios'

function PersonalShop() {


    const shop = useSelector((state) => state.shop)
    const wishList = useSelector((state) => state.shop.wishList)
    const basket = useSelector((state) => state.shop.basket)
    let [inputValue, setInputValue] = useState("")
    let [result, setResult] = useState([])
    let [count, setCount] = useState(null)
    let [currentPage, setCurrentPage] = useState(1)
    let [v1WebsiteVal, setV1WebsiteVal] = useState("All")
    let [v1GenderVal, setV1GenderVal] = useState("All")
    let [v1DepVal, setv1DepVal] = useState("All")
    let [showProgressbar, setShowProgressbar] = useState()
    let [errorMessage, setErrorMessage] = useState()
    let [showHistory, setShowHistory] = useState()
    let [listOfSearchHistory, setHistoryList] = useState([])
    let [noDataMsg, setNoDataMsg] = useState()
    let [noDataIcon, setNoDataIcon] = useState()
    let [lastDate, setLastDate] = useState()
    let [firstDate, setFirstDate] = useState()
    let [image, setImage] = useState()
    const { product_Id, title } = useParams()
    const navigate = useNavigate();
    const [searchKey, setSearchKey] = useState()
    const isPersonalized = useSelector((state) => state.user.isPersonalized)
    const persona = useSelector((state) => state.user.persona)
const [uploadPopUp,setuploadPopUp]=useState(false);




useEffect(()=>{


    if(result.length>0)
    {
        editResult(result)
    }

},[shop.wishList,shop.basket])

useEffect(() => {


    if (product_Id) {

        findSimilarsItems(1, '')

    } else{
      
            if(inputValue!=='')
            {
                ResultofTextSearch(inputValue,1,'')
            }
        }
       


}, [v1GenderVal, v1WebsiteVal, v1DepVal, product_Id,isPersonalized,persona])

    useEffect(() => {

        textSeacrhHistory().then((res) => {
            setHistoryList(res.data.history)
        }).catch((err) => {
            console.log(err);
        })

    }, [])

    
    function handelPagintation(page) {

        if (product_Id) {

            findSimilarsItems(page,searchKey)
        }
        else {
            ResultofTextSearch(inputValue, page,searchKey)
        }
    }

    function getImageSearchResult(page,search_key) {

        setShowProgressbar(true);
         setCurrentPage(page);
         setResult([])

        imageSearch(v1WebsiteVal, v1GenderVal, "All", page, image,search_key).then((response) => {
            if (response.status === 200) {
                if (response?.data?.message?.length > 0) {
                    
                    setResult(response?.data.message)
                    setCount(response.data.message.count)
                    setSearchKey(response.data.search_key)


                }
                else {
    
                    
                    setNoDataIcon('../NoQualifiedData.svg')
                    setNoDataMsg('There’s no more data in this category. We’re brining in more data soon!')
                    setCount(0)

                }

            } else if (response.status === 204) {
                setNoDataIcon('../oops.svg')
                setNoDataMsg('No Data Found')
                setCount(0)
            }

            setShowProgressbar(false);

        }).catch((err) => {
            setErrorMessage(err?.message)
        })

    }


    function editResult(result2){
       

        let news= result2.map((product)=>{
   
           let index=  shop.wishList?.findIndex((p)=> p.product_id===product.product_id)
                  let isWishList=false;
                  let isBasket=false;
                 
          if(index!==-1){
            
           isWishList=true
          }
   
            index=  shop.basket?.findIndex((p)=> p.product_id===product.product_id)
            
            if(index!==-1)
            {
               isBasket= true
            }

            return {...product,basket:isBasket,wishList:isWishList}
           
         })
           
         setResult(news)
       }


    async function ResultofTextSearch(value,page,search_key){
        setCurrentPage(page)
        setShowProgressbar(true)
        setShowHistory(false)
        setResult([])

        textSeacrh(value,v1WebsiteVal,v1GenderVal,v1DepVal,page,product_Id?'15':'16',search_key,isPersonalized?persona._id:'').then((response)=>{
            
            if(response.data.message.length !== 0){
                  
                      editResult(response?.data?.message)
                     setCount(response.data.count)
                     setSearchKey(response.data.search_key)
                    
                     setShowProgressbar(false)
            }
            else{
                setNoDataIcon('../NoQualifiedData.svg')
                setNoDataMsg('There’s no more data in this category. We’re brining in more data soon!')
                 setCount(null)
            }
        
      

        }).catch((error) => {

            setShowProgressbar(false)
            setErrorMessage(error.message)
        })

    }
    async function handleEnterKey(event) {
        if (event.key === 'Enter') {
            await ResultofTextSearch(inputValue, 1,'')
        }
    }

    async function findSimilarsItems(page, search_key) {

        setResult([])
        setCurrentPage(page);
        setShowProgressbar(true)

        findSimilars(v1WebsiteVal, v1GenderVal, v1DepVal, page, product_Id, search_key,isPersonalized?persona._id:'').then((response) => {

            if (response.status === 200) {
                if (response.data.message.length !== 0) {

                    editResult(response?.data?.message)
                    setCount(response?.data?.count ?? 0)
                    setSearchKey(response?.data?.search_key)
                } else {
                    setCount(0)
                    setNoDataIcon('../NoQualifiedData.svg')
                    setNoDataMsg('There’s no more data in this category. We’re brining in more data soon!')

                }

            }
            else if (response.status === 204) {
                setCount(0)
                setNoDataIcon('../oops.svg')
                setNoDataMsg('No Data Found')

            }

            setShowProgressbar(false)

        }).catch((error) => {
            if (axios.isCancel(error)) {
                console.log('Request canceled:', error.message);
              } else {
                setErrorMessage(error.message)
                setShowProgressbar(false)
              }

        
        })


    }
 function back(){
        SOURCE.cancel('cancled') ;
        navigate(-1); 
        resetSource();
    }

    return (
        <div className="container-fluid">
            {errorMessage ? <ErrorMessageComponent message={errorMessage} /> : (
                <div className="row">
                    <div className="col-2"></div>
                    <SideBarComponent sendWebsiteValueToSideBarCom={setV1WebsiteVal} sendDepValueToSideBarCom={setv1DepVal} sendGenderValueToSideBarCom={setV1GenderVal}></SideBarComponent>


                    <div className="col-12 col-lg-9 mx-auto  mt-5 position-relative">
                        {/* first row that contain title and back button */}

                        <p className=" text-center  mb-4  mx-auto w-100" style={paragraphStyle}>Personal shop</p>

                        {/******************* fisrt and last date of items ********************/}
                        {result?.length > 0 && <p className="text-center ">

                            {firstDate ? <span >The first item added  : <span className="px-1" style={{ background: "#F3F3F3" }}> {firstDate.toDateString()}</span> </span> : <></>}
                            {lastDate ? <span className="d-block d-sm-inline" >The last item added : <span className="px-1" style={{ background: "#F3F3F3" }}>{lastDate.toDateString()}</span> </span> : <></>}
                        </p>}


                        {/*********** personalization Row****************/}

                        <div className="px-2 my-4">
                            <Personalization />

                        </div>

                        {/************************  search bar ************************/}

                        <div className='col-6 mx-auto mb-4 position-relative'>
                            <figure onClick={()=>{setuploadPopUp(false)}}  className='position-absolute end-0 mt-2  me-2'><img style={{ height: '24px', width: '24px' }} src='/camera.svg' alt='search' /></figure>
                            <figure className='position-absolute mt-2  ms-2'><img style={{ height: '24px', width: '24px' }} src='/search-input.svg' alt='search' /></figure>
                            <input type="text" className="form-control rounded-3 px-5 py-2 col-6 " placeholder="Describe any thing in your mind" aria-label="Search" value={inputValue} onKeyDown={handleEnterKey} onChange={(e) => { setInputValue(e.target.value) }} />
                        </div>

                        
                        {/************************ image search box ************************/}
                        <div hidden={uploadPopUp} className={`${styles.imageUpload}`}>
                         
                        <div className='row'>
                        <p className='col-4'></p>
                            <p className='col-4 fw-bold'>Search any image</p>
                            <figure onClick={()=>{setuploadPopUp(true)}} className='col-4 text-end pointer'><img src='/close.svg'/></figure>
                        </div>
                        <section className={` ${styles.backgroudImg}`}>
                        <div className=' w-100 h-75  d-flex justify-content-center align-items-center'>
                      <NewFileUploader setImage={setImage} type={'shop'}/>
                      </div>
                        </section>

                        </div>

                        {/************************ your personal shop ************************/}
                        <div style={{ backgroundColor: '#FBFBF9' }} className='w-100 rounded-3 fs-4 fw-bold py-5 mx-auto my-4 '>
                            Your personal shop
                        </div>
                       {listOfSearchHistory?.length>0 &&<>
                        <p className='text-start my-1 py-0 fw-bold'>Top Searched </p>
                        <p style={{ fontSize: '14px' }} className='text-start my-0 py-0' >Top searched items by users similar to me’</p>
                        <div className='row my-3 mb-5 overflow-x-auto'>
                            {
                                listOfSearchHistory.map((item,index)=>{
                                    return(
                                        <Chip 
                                        key={index+item}
                                        style={chip}
                                              className='me-2'
                                            onClick={function () { 
                                                setInputValue(item.query)
                                                ResultofTextSearch(item.query,1,'')

                                        }}
                                            size="md"
                                            label={item.query}
                                            variant="outlined"
                                                  />  )

                                })
                            }
                           
                              
                        </div>
                        </>}
                          {/*********** >Recommended Product and count ****************/}
                        {count && <div className="mt-4">
                                        <p className='text-start my-1 py-0 fw-bold'>Recommended Products</p>
                                         <p style={{ fontSize: '14px' }} className='text-start my-0 py-0' >Based on my preferences</p>    

                                         <div className='d-flex justify-content-between align-items-center w-100 '>
                                             <p className=" text-start  col-2  mt-3  " style={countNumStyle}>{count} items found </p>
                                             <div className=' col-3 justify-content-end d-flex' > 
                                             <div className='mt-1 me-2 rounded-circle'  style={{height:'15px',width:'15px', backgroundColor:'#16D986'}}> </div>
                                             <span style={{ fontSize: '14px' }} className='text-start my-0 py-0' >Pesonalized items</span>   
                                             </div>
                                          
                                          </div>
                                       
                                        </div>}

              
                        <div className="row">
                            {/***********  Similars to image ****************/}
                            {product_Id &&

                                <div className="col-3 mt-3 ">
                                    <div className="col-11">
                                        <button className=" btn border-0  mb-2 d-block me-auto" onClick={back} ><img src='/curved-back.svg' alt="back" /> Back </button>
                                        <img className="card-image rounded-1  w-100" src={`${IMAGEURL}${product_Id}_0.jpg`} alt="img" ></img>
                                        <p className="mt-1 text-start text-over-fLow" > {title ?? ''}</p>
                                    </div>

                                </div>
                            }
                            {/*********** result of text search ****************/}
                            {showProgressbar ?
                                <div className="row m-auto   pt-5 col-lg-9 ">

                                    <ProgressBarComponent></ProgressBarComponent>
                                </div>

                                :
                                (result?.length > 0 ?
                                    <div className={"row  my-4 " + (product_Id ? 'col-lg-9' : 'w-100')}>

                                        {result?.map((product) => {

                                            return (
                                                <div key={product.product_id} className={" col-6  py-2 " + (product_Id ? 'col-md-4' : ' col-md-3')}>
                                                    <CardText p={product} type={'shop'} />
                                                </div>
                                            )
                                        })}

                                        {Math.ceil(count / 16) > 1 && <Pagination count={Math.ceil(count / 16)} page={currentPage} className="d-flex mt-2  justify-content-center col-12" shape="rounded" onChange={(e, page) => { handelPagintation(page) }} />}

                                    </div>
                                    :
                                    <div className="mt-5">
                                        {noDataMsg &&
                                            <>
                                                <img style={{ width: '100px', height: "100px" }} src={noDataIcon} width="0px" height="60px" alt='nodata'></img>
                                                <p style={{ marginTop: "8px", fontFamily: "Signika" }}>{noDataMsg}</p>
                                            </>}
                                    </div>)}

                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default PersonalShop
let countNumStyle = {
    fontFamily: "Signika",
    fontWeight: "400",
    "fontSize": "13px",
    color: "#888888"

}
let paragraphStyle = {
    fontFamily: "Signika",
    fontWeight: "550",
    "lineHeight": "25px",
    "fontSize": "23px",
    "float": "left"
}

const chip={
    width:'fit-content'
}