/* eslint-disable no-restricted-globals */
import { Link } from "react-router-dom"

export default function ErrorMessageComponent(props){
    let errorMsg = props.message
    let spanStyle = {
        fontFamily:"Space Grotesk",
        color:"#FF01A0",
        "text-decoration": "underline",
        fontSize:"25px" 
    }
    return (
        <div className="container-fluied">
            <nav class="navbar">
               
                    <img src="/v1Demo.png" width="180px" height="100px" alt="demo"></img>
                
            </nav>

            {/* <div className="row"> */}
                <div class="centered-div">
                    <div className="ml-3">
                       
                    </div>
                    <div>
                        <p style={{marginLeft:"70px" , fontFamily:"Space Grotesk" , fontSize:"40px" , fontWeight:"500"}}>Huh!</p>
                    </div>
                    <div>
                        <p style={{marginLeft:"70px" , fontFamily:"Space Grotesk" , fontSize:"25px"}}>This Isn’t Supposed to happen. </p>
                    </div>
                    <div>
                        <p style={{marginLeft:"70px" , fontFamily:"Space Grotesk" , fontSize:"25px" , lineHeight:"1px"}}>Please <a className=" curso" onClick={()=>{location.reload()}} style={spanStyle}>Refresh!</a></p>
                    </div>
                    <div className="mt-5 ">
                        <div style={{height:"30px"}}></div>
                    </div>
                    <div className="mt-5 ">
                        <p style={{marginLeft:"70px" , fontFamily:"Space Grotesk" , fontSize:"25px" , lineHeight:"1px"}}> Error : {errorMsg}  </p>
                    </div>
                </div>
            {/* </div> */}

        </div>
    )
}