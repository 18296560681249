
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.css';
import "bootstrap/dist/js/bootstrap.min.js";
import axios from 'axios';
import { MANAGEMENT } from './config';
import { Provider } from 'react-redux'
import { store } from './store';
const root = ReactDOM.createRoot(document.getElementById('root'));
 const  date = new Date()


window.addEventListener("beforeunload",  (e) => 
 {  
   e.preventDefault();

 const email= localStorage.getItem("email"); 
 const  endDate = new Date()
 const startSession= localStorage.getItem("startSession"); 
  const body= {
    email:email,
  "start_session":startSession??date.toISOString(),
  "end_session":endDate.toISOString()
}
  axios.post(`${process.env.REACT_APP_API_GATEWAY}${MANAGEMENT}login/session`,body)
  
  localStorage.setItem("sent",date.toISOString());  

});


localStorage.setItem("startSession",date.toISOString());  


root.render(
  <React.StrictMode>
    <Provider store={store}>
    <App />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
