/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useState } from "react";

import { Pagination } from "@mui/material";

import { useNavigate, useParams } from "react-router-dom";
import SideBarComponent from "../ui-components/SideBarComponent";
import ErrorMessageComponent from '../component/ErrorMessageComponent'
import ProgressBarComponent from "../ui-components/ProgressBarComponent";
import { getSimilarsList, getsimilarsCount } from "../services/clientManagementService";
import Card from "../ui-components/Card";
import { IMAGEURL, SOURCE, resetSource } from "../config";
import { findSimilars } from "../services/similarService";
import Personalization from "../component/personalization/Personalization";
import { useSelector } from 'react-redux';
import axios from "axios";


export default function Similar() {
    const shop=useSelector((state)=> state.shop)
    const isPersonalized=useSelector((state)=> state.user.isPersonalized)
    const persona=useSelector((state)=> state.user.persona)
    const navigate = useNavigate();
    const { product_Id, title } = useParams()
    const [result, setResult] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [itemCount, setitemCount] = useState(null)
    const [filters, setFilters] = useState({website:'All',
    target_audience:'All',
    department:'All',
})
    const [fetch, setFetch] = useState(false) 
    const [errorMessage, setErrorMsg] = useState()
    const [loading, setLoading] = useState(true)
    const [lastDate, setlastDate] = useState()
    const [firstDate, setFirstDate] = useState()
    const [noDataMsg, setNoDataMsg] = useState("No Data Found")
    const [noDataIcon, setNoDataIcon] = useState("../oops.svg")
    const [searchKey, setSearchKey] = useState()






    useEffect(()=>{
        if(result.length>0)
        editResult(result)
    
    },[shop.wishList,shop.basket])

    useEffect(() => {
  
        if (product_Id) {

            findSimilarsItems(1, '',product_Id)

        } else {

            findSimilarsItems(1, '','')

            getSimilarsProducts(1)

            getsimilarsCount(filters).then((res) => {

                // setitemCount(res?.data?.count ?? 0)

            })
                .catch((e) => {
                    console.log(e);
                })
        }


    }, [ fetch,product_Id,isPersonalized,persona])


    function editResult(result2){
        
        let news= result2.map((product)=>{
   
           let index=  shop.wishList.findIndex((p)=> p.product_id===product.product_id)
                  let isWishList=false;
                  let isBasket=false;
          if(index!==-1){
       
           isWishList=true
          }
   
            index=  shop.basket.findIndex((p)=> p.product_id===product.product_id)
            
            if(index!==-1)
            {
               isBasket= true
            }
            return {...product,basket:isBasket,wishList:isWishList}
           
         })
   
         setResult([...news])
       }
   
    async function findSimilarsItems(page, search_key,productId) {

        setResult([])
        setCurrentPage(page);
        setLoading(true)

        findSimilars(filters, page, productId, search_key,isPersonalized?persona._id:'').then((response) => {

            if (response.status === 200) {
                if (response.data.message.length !== 0) {

                    editResult(response?.data?.message)
                    setitemCount(response?.data?.count ?? 0)
                    setSearchKey(response?.data?.search_key)
                } else {
                    setitemCount(0)
                    setNoDataIcon('../NoQualifiedData.svg')
                    setNoDataMsg('There’s no more data in this category. We’re brining in more data soon!')

                }

            }
            else if (response.status === 204) {
                setitemCount(0)
                setNoDataIcon('../oops.svg')
                setNoDataMsg('No Data Found')

            }

            setLoading(false)

        }).catch((error) => {
            if (axios.isCancel(error)) {
                console.log('Request canceled:', error.message);
              } else {
                setErrorMsg(error.message)
            
              }

            
        })


    }


    function getSimilarsProducts(page) {
        setResult([])
        setCurrentPage(page)
        setLoading(true)

        getSimilarsList(filters, page).then((res) => {

            if (res.data.products.length > 0) {
        
                setlastDate(new Date(res.data.last_product_date))
                setFirstDate(new Date(res.data.first_product_date))

            }
            // setLoading(false);

        }).catch((err) => {

            console.log(err.message)

        })
    }



    function handelPagintation(page) {

        if (product_Id) {

            findSimilarsItems(page, searchKey,product_Id)
        }
        else {
            findSimilarsItems(page, searchKey,'')
        }
    }

    function back(){
        SOURCE.cancel('cancled') ;
        navigate(-1); 
        resetSource();
    }

    return (
        <div className="container-fluid">
            {errorMessage ?
                <ErrorMessageComponent message={errorMessage} />
                : (

                    <div className="row ">
                        <SideBarComponent setFetch={setFetch}  selectedFilters={filters} setSelectedFilters={setFilters}></SideBarComponent>

                        <div className="col-2"></div>
                        <div className="col-12 col-lg-9  mx-auto mt-5">
                            {/* first row that contain title and back button */}
                            <div className="row">

                                <div className=" mx-auto">

                                    <p className="text-center    " style={paragraphStylee}>Similar Items Recommender </p>
                {/*********** personalization Row****************/}

                        <div className="px-2">
                            <Personalization />

                        </div>
                    
                                </div>

                                <div className="row my-4  mx-auto  px-0  w-100">


                                    {product_Id ?

                                        <div className="col-3 ">
                                            <div className="col-11">
                                                <button className=" btn border-0  mb-2 d-block me-auto" onClick={back} ><img src='/curved-back.svg' alt="back" /> Back </button>
                                                <img className="card-image rounded-1  w-100" src={`${IMAGEURL}${product_Id}_0.jpg`} alt="img" ></img>
                                                <p className="mt-1 text-start text-over-fLow" > {title ?? ''}</p>
                                            </div>

                                        </div>



                                        : <p className="text-center ">
                                            {result.length > 0 && <><img src='../cloud-add.svg' style={{ height: "24px", width: "24px" }} />
                                                <span >The first item added  : <span className="px-1" style={{ background: "#F3F3F3" }}> {firstDate?.toDateString()}</span> </span>
                                                <span >The last item added : <span className="px-1" style={{ background: "#F3F3F3" }}>{lastDate?.toDateString()}</span> </span>
                                            </>}
                                        </p>
                                    }

                     
                                    {/* Here is the Result  */}
                                    {loading ? (

                                        <div className="row m-auto col-lg-9 ">

                                            <ProgressBarComponent></ProgressBarComponent>
                                        </div>

                                    )
                                        :
                                        (result?.length > 0 ?
                                            <div className={"row  my-3 " + (product_Id ? 'col-lg-9' : 'w-100')}>
                                                {itemCount && <div className="mt-4">
                                                    {product_Id && <p className=" text-start  mx-auto  mb-3" >Search results for this image</p>}
                                                    <p className=" text-start my-0 py-0   mx-auto" style={countNumStyle}>{itemCount} items found </p>
                                                </div>}
                                                {result?.map((p) => {

                                                    return (
                                                        <div key={p.product_id} className={" col-6  py-2 " + (product_Id ? 'col-md-4' : ' col-md-3')} >
                                                            <Card  p={p} type={'similar'} />
                                                        </div>
                                                    )
                                                })}

                                                {Math.ceil(itemCount / 16) > 1 && <Pagination count={Math.ceil(itemCount / 16)} page={currentPage} className="d-flex justify-content-center  w-100" shape="rounded" onChange={(e, page) => { handelPagintation(page) }} />}

                                            </div>

                                            :
                                            <div className="row">
                                                <div className="mt-5">
                                                    <img style={{ width: '100px', height: "100px" }} src={noDataIcon} width="100px" height="60px"></img>
                                                    <p style={{ marginTop: "8px", fontFamily: "Signika" }}>{noDataMsg}</p>
                                                </div>
                                            </div>


                                        )

                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                )}
        </div>

    )
}




const paragraphStylee = {
    fontFamily: "Signika",
    fontWeight: "300",
    lineHeight: "25px",
    fontSize: "23px",


}

const verticalLine = {
    borderLeft: "1px solid #E6EDFF",
    height: "700px",
    marginTop: "25px",
    width: "20px"
}


const v1PStyle = {
    textAlign: "left",
    fontFamily: "Signika",
    fontWeight: "100",
}



const countStyle = {
    fontFamily: "Signika",
    fontWeight: "500",
    fontSize: "16px",

}
const countNumStyle = {
    fontFamily: "Signika",
    fontWeight: "400",
    fontSize: "16px",
    color: "#888888"

}