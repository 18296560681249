export default function ProgressBarComponent(){
    
    let styleProgress ={
        height: "7px"
    }

    let styleProgressBar ={
        "width": "25%",
        backgroundColor:"#6FCAD4",
        animation: "moveProgressBar 2s linear infinite",

    }
    
    return (
        <div>
           <p style={v1PStyle}>Loading Results</p>
            <div className="progress" style={styleProgress}>
            <div className="progress-bar  progress-bar-animated" role="progressbar" style={styleProgressBar} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
        </div>
    )
}


let v1PStyle = {
    "textAlign": "left",
    fontFamily: "Signika",
    fontWeight: "100",
}