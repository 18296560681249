import {  AUTOTAGGING, apiKey } from "../config"
import  axios from 'axios';

export function autoTag(baseImg){

   const headers={
        "Content-Type":"multipart/form-data",
            'x-tt-api-key':apiKey
        
    }

        const body = {
          
            image: baseImg.split(",")[1],
            lang:"en",
        }

     
    return  axios.post(`${process.env.REACT_APP_API_GATEWAY}${AUTOTAGGING}` , body,{headers})
 
    }