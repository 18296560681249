import React, { useState } from 'react'
import { IMAGEURL } from '../config';
import { useNavigate } from 'react-router-dom';
import { sendMessageSQS } from '../sqs-config';
import '../baseLayout.css'
import { useDispatch, useSelector } from 'react-redux';
import { removeBasket, removeWishList, setBasket, setWishList } from '../slices/shopSlice';
import { display, fontSize, fontWeight, padding } from '@mui/system';
import { Tooltip } from '@mui/material';

function CardText({p,type}) {

 const dispatch=useDispatch()
 const persona=useSelector((state)=> state.user.persona)
 const isPersonalized=useSelector((state)=> state.user.isPersonalized)

 


  const navigate = useNavigate();
    const options = {
        weekday: "short",
        month: "short",
        day: "2-digit",
        year: "numeric"
      };
      const dateObj = new Date(p.date??p?.last_edit??p?.createdAt??'');
 

   const  clickOnProduct=(name,weight)=>{

        if(isPersonalized){
    switch(name){

      case 'click':
      { 
        if(type==='similar')
        {
          navigate(`/similar/${p.product_id}`)

        }else if(type==='text'){

        navigate(`/text-search/${p.product_id}`)
      }
      
      else if(type==='shop'){
        navigate(`/personal-shop/${p.product_id}`)
      }
        
        break;
      }
      case 'wishList':
      {

        let updatedProduct={...p,  wishList:!p.wishList}
      
        
            dispatch(setWishList(updatedProduct))
        
        
        break;

      }
      case 'unwish':
        {
  
          let updatedProduct={...p,  wishList:!p.wishList}
        
          

           
              dispatch(removeWishList(updatedProduct))
            
          
        
          break;
  
        }
      case 'basket':
        {
          let updatedProduct={...p,  basket:true}
              

              dispatch(setBasket(updatedProduct))
            
         
          break;
  
        }
        

      default:{}
   
      }

    sendMessageSQS(
      {
        persona_id:persona._id,
        weight:weight,
        event_type:name,
        product_id:p.product_id,
          user_id: persona.user,
          age: persona.age,
          gender: persona.gender,
          country: persona.country,
          city: persona.city,
          region:persona.region
        
      }
      ).then((res)=>{
       
       
      }).catch((err)=>{console.log(err)})
    }else{


      if(type==='similar')
      {
        navigate(`/similar/${p.product_id}`)

      }
    else if(type==='text')
    {
      navigate(`/text-search/${p.product_id}`)
    }

    }
  
    }

      
        
     return(
        <div onClick={()=>{clickOnProduct('click',1)}} className=" position-relative mb-2">
            <div className="card-image" >
        
          <img  className="w-100 h-100 rounded-1" src={`${IMAGEURL}${p?.product_id}_0.jpg`} alt=""   ></img>
    
          </div>
          <p className="py-0 text-start my-0 text-over-fLow" > {p?.title??''}</p> 
          <div  className=" text-start text-over-fLow ">

             {p.last_edit&&p.score&&<><span className="rounded-3 px-1 pb-1" style={{color:"#FF01A0",fontSize:"12px",background:"#F3F3F3"}} ><img src="/similarity.svg"/> {Number.parseInt(p.score*100)}%</span>
              <span className="text-secondary " style={{fontSize:"12px"}} > {Intl.DateTimeFormat("en-US", options).format(dateObj)}</span></>   }
             
             {p?.diff_rank!=0&& 
                <Tooltip title={`Position ${p?.diff_rank>0?'up':'down'}: ${p?.diff_rank}`} placement="top">
            {p?.diff_rank&& <p  onClick={(e)=>{e.stopPropagation()}}  className=' badge ms-4 pe-2  ' style={p?.diff_rank>0?badge2:badge}><img src={p?.diff_rank>0?'/green-up.svg':'/red-down.svg'}/> { Math.abs( Number.parseInt( p?.diff_rank))}</p>}
             </Tooltip> }
             { type==='similar'&& <button className="btn_similar text-center" onClick={()=>{ clickOnProduct('click',1)}}> <img src='/btnSimilar.svg' alt='btn'/> Find similar <img src='/arrow-right-1.svg' alt='arrow'/></button> }
           
           </div>

           { isPersonalized&&type!=='image'&&<div  style={buttons} >
            <button  style={button} onClick={(e)=>{e.stopPropagation();( p?.wishList?clickOnProduct('unwish',1): clickOnProduct('wishList',2))} } className='btn '><img src={p?.wishList?'/fav2.svg':'/fav.svg'} alt="fav"/></button>
            <button disabled={p?.basket} style={button} onClick={(e)=>{e.stopPropagation(); clickOnProduct('basket',3)}} className='btn  my-3'><img src='/addBasket.svg' alt="basket"/></button>
            {/* <button style={button} onClick={(e)=>{e.stopPropagation(); clickOnProduct('purchased',5)}} className='btn'><img src='/purchased.svg' alt="purchased"/></button> */}
           </div>}
            

        </div>
     
  )
}

export default CardText


const buttons={
  top:"15px",
  right:"15px",
  position:"absolute",
  zIndex:"5"

}

const button={
 border:"none",
 height:"30px",
 width:"30px",
 backgroundColor:'white',
 display:'flex',
 justifyContent:"center",
 alignItems:"center"
 

}

const badge={
  border:'1px solid red',
  borderRadius:'100px',
  display:'inline-block',
 
  marginLeft:'5px',
  backgroundColor:'#FFF2F2',
  color:'red',
  width:'60px',
  height:'24px',
  fontSize:'14px',
  fontWeight:"500"

}
const badge2={
  border:'1px solid #01C04D',
  borderRadius:'100px',
  display:'inline-block',
  marginLeft:'5px',
  backgroundColor:'#F2FFF7',
  color:'#01C04D',
  width:'50px',
  height:'24px',
  fontSize:'12px',
  fontWeight:"500"

}