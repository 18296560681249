import { FILTERS, IMAGE_SEARCH, apiKey } from "../config"
import  axios from 'axios';

export function imageSearch(filters,page,baseImg,searchKey){

    const headers={
        'x-tt-api-key':apiKey
    }

        const body = {
           
            image: baseImg.split(",")[1],
            page,
            page_limit: 16,
            filters:[       
                {target_audience : filters['target_audience'] === "All" ?  FILTERS.target_audience:[filters["target_audience"]] },
                {website :filters["website"] === "All" ?FILTERS.website  :[filters["website"]]} ,
                {department: filters["department"] === "All" ?FILTERS.department :[filters["department"]]},
          
            ]
           
        }

        if(searchKey&&page>1){
           
            body.search_key=searchKey
        }

     
    return  axios.post(`${process.env.REACT_APP_API_GATEWAY}${IMAGE_SEARCH}` , body,{headers})
 
    }