import { Link, useNavigate } from "react-router-dom";
import styles from './IntroPage.module.css'
import emailjs from 'emailjs-com';
import { useContext, useEffect, useState } from "react";
import { getAllProductsCount } from "../../services/clientManagementService";
import { MyContext } from '../../MyContext';
import { SETFILTERS } from "../../config";
import { margin } from "@mui/system";
import { useDispatch, useSelector } from 'react-redux';
import { setFilters } from "../../slices/filtersSlice";


export default function IntroPage() {
    const navigate=useNavigate()
    const dispatch = useDispatch()
    const filters = useSelector((state) => state.filters.filters)
    let [productsNumber, setProductNumber] = useState()
    let [errorMsg, setErrorMsg] = useState()
    let [sentPEmail, setSentPEmail] = useState(false)
    let [sentMEmail, setSentMEmail] = useState(false)
    let [inputMatch, setInputMatch] = useState('')
    let [inputPredict, setInputPredict] = useState('')
    const buttons = [
    { name: 'Neural Text Search', icon: '/text-search.svg', link: '/text-search' },
    { name: 'Similar Items Recommender', icon: '/similar-items.svg', link: '/similar' },
    { name: 'Items Auto-Tagging', icon: '/auto-tag.svg', link: '/auto-tagging' },
    { name: 'Image Search', icon: '/image-search.svg', link: '/image-search' },
 

    ]

    const buttonsPredict = [
    { name: 'Demand Forecast', icon: '/demand.svg', link: '' },
    { name: 'SKU Market Demand Predictor', icon: '/sku.svg', link: '' },
    { name: 'Item Velocity Predictor', icon: '/velocity.svg', link: '' },
    ]
    const buttonsMatch= [
        { name: 'Personalization', icon: '/personalization.svg', link: '/text-search' },
        { name: 'Dynamic Pricing', icon: '/dynamic-pricing.svg', link: '' },
        { name: 'Demand Generation', icon: '/generation.svg', link: '' },
        ]

        function clicked(name){
            if(name==='Personalization'){
                localStorage.setItem('personalization','true')
                  navigate('/text-search')
                  
               
                
            }
        }
   

    useEffect(() => {

        getAllProductsCount().then((res) => {
            setProductNumber(res.data.count)
            localStorage.setItem('prod_count', JSON.stringify(res.data.count))

        }).catch((err) => {
            setErrorMsg(err.message)
        })


        //to reset filters while moving between services
        const filters = JSON.parse(localStorage.getItem('filters'))
        dispatch(setFilters(filters))
        SETFILTERS(filters);


    }, [])

   function sendEmail(service){

    emailjs.send('service_uf55nnp', "template_z5xdd0f",{ recipient: '', subject: 'new user',
    message:  service==='predict'? ` From Predict \n email: ${inputPredict} `:` From Match \n email: ${inputMatch}` }, 
    'k4IZo-RTvYMlW0RAu')
     .then((response) => {
       console.log('Email sent successfully:', response);
       
       if(service==='predict')
       {
        setInputPredict('')
        setSentPEmail(true)
       }
       else{
        setInputMatch('')
        setSentPEmail(false)
       }
     
    
   })
     .catch((error) => {
       console.error('Error sending email:', error);
   
     });
   }

    return (

        <div className="pb-5">
            <img src="../TTLogo.svg" width="170px" height="80px" alt="demo"></img>
            <div className={`${styles.line}  mb-5`}> </div>

            <p className={styles.title}> Retail & Fashion Focused <br />AI-as-a-Service</p>
            <p className={styles.numbers}> Our demo database features <span className="fw-bold">[{productsNumber?.toLocaleString('en-US')}] </span> fashion items, from <span className="fw-bold">[{filters.website.length}]</span>  different e-commerce websites</p>
          <div className={styles.mainDiv}>

            <div className={styles.explore} >
               
                <p style={{ fontSize: "32px", fontWeight: '500'}}> Explore</p>

                {buttons.map((btn, index) => {
                    return (
                        
                        <div key={index} className={`${styles.exploreBtn} px-2`} onClick={()=>{ navigate(btn.link)}}>
                            
                            <img className={styles.icon} style={{ width: '30px', height: '30px' }} src={btn.icon} alt='' /> 
                        <p className="text-start me-auto mt-3">{btn.name} </p>
                      <img className={`${styles.arrow} `} src={'/arrow-left.svg'} style={{ width: '22px', height: '22px' }}  alt=''/>  
                    
                        </div>
                      
                    )
                })}
            </div>

            <section className={`${styles.section}  mx-auto  mt-0 pt-5 `} >

                <div className={`${styles.predict} col-4`} >
                <p className={styles.comingSoon} style={{backgroundColor:'#CCFFFE'}}>Coming Soon</p>
                    <p style={{ fontSize: "32px", fontWeight: '500', }}> Predict</p>
                    {buttonsPredict.map((btn, index) => {
                        return (
                            <button key={index} className={`${styles.predictBtn} btn my-2 ps-2 text-start `} ><img className="me-2" style={{ width: '30px', height: '30px' ,}} src={btn.icon} alt='' />{btn.name} </button>
                        )
                    })}
                     <div className="position-relative col-11 mt-3">
                    <input className={styles.input} value={inputPredict}  onChange={(e)=>{setInputPredict(e.target.value)}}  placeholder="Enter your email"/>
                    <button disabled={inputPredict.length<6} onClick={()=>{sendEmail('predict')}} className={`${styles.joinBtn} btn btn-dark px-0`}> Join Waitlist</button>
                    </div>
                  {sentPEmail&&  <p className={styles.success} ><img className="mb-1" src="/success.svg"/> Got it! 🎉 You're now on our exclusive waitlist! 🚀</p>}
                </div>
                <div className={`${styles.match}  col-4`}>
                <p className={styles.comingSoon} style={{backgroundColor:'#F5FBC9'}}>Coming Soon</p>
                    <p style={{ fontSize: "32px", fontWeight: '500' }}> Match</p>
                    {buttonsMatch.map((btn, index) => {
                        return (
                            <button key={index} onClick={()=>{clicked(btn.name)}} className={`${btn.name==='Personalization'? styles.predictBtnShow :styles.predictBtn} btn  ps-4 my-2 text-start`} ><img className="me-2" style={{ width: '30px', height: '30px' }} src={btn.icon} alt='' />{btn.name} </button>
                        )
                    })}
                     <div className="position-relative col-11 mt-3">
                    <input value={inputMatch}  onChange={(e)=>{setInputMatch(e.target.value)}} className={styles.input} placeholder="Enter your email"/>
                    <button disabled={inputMatch.length<6} onClick={()=>{sendEmail('match')}}  className={`${styles.joinBtn} btn btn-dark px-0`}> Join Waitlist</button>
                    </div>
               {   sentMEmail  && <p className={styles.success} ><img className="mb-1" src="/success.svg"/> Got it! 🎉 You're now on our exclusive waitlist! 🚀</p> } </div>
               

                

            </section>
            </div>
        </div>
      

    )
}




