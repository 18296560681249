import axios from "axios";
import { FILTERS, TEXT_SEARCH, TEXT_SEARCH_V2, apiKey } from "../config";
import { ulid } from "ulid";


export function textSeacrh(text,filters,page,page_limit,searchKey,persona_id){

    const headers ={
        client : "ttdemo",
        key:ulid(),
        'x-tt-api-key':apiKey
    }

    const body= {
        text,
        filters:[       
            {target_audience : filters['target_audience'] === "All" ?  FILTERS.target_audience:[filters["target_audience"]] },
            {website :filters["website"] === "All" ?FILTERS.website  :[filters["website"]]} ,
            {department: filters["department"] === "All" ?FILTERS.department :[filters["department"]]},
      
        ],
          page,
          page_limit
        } 
        if(searchKey&&page>1){
           
            body.search_key=searchKey
        }
 
        if(persona_id){
           
            body.persona_id=persona_id;
            body.personalisation=true
        }
        else{
            body.personalisation=false
        }

         
    return  axios.post(`${process.env.REACT_APP_API_GATEWAY}${TEXT_SEARCH_V2}` , body, {headers:headers})
 
    }



    export function textSeacrhHistory(){
        return axios.get(`${process.env.REACT_APP_API_GATEWAY}${TEXT_SEARCH}/v1/textsearch/history`);
    }