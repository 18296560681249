import { configureStore } from '@reduxjs/toolkit'
import   filtersReducer from './slices/filtersSlice'
import   shopReducer from './slices/shopSlice'
import userReducer from './slices/userSlice'

export const store = configureStore({
  reducer: {
   filters:filtersReducer,
   shop:shopReducer,
   user: userReducer
  },
})