import { FILTERS, SIMILARITY, SIMILARITY_V2, SOURCE, apiKey, source } from "../config"
import  axios from 'axios';

export function findSimilars(filters,page,id,searchKey,persona_id){

const filtersToSend=[]

Object.keys(filters)?.forEach(key => {
    if(filters[key]!=='All')
        {
            filtersToSend.push({[key]:[filters[key]]})
        }
    
});
     
const headers={
    'x-tt-api-key':apiKey
}
        const body = {
            product_id: id,
            page,
            page_limit: 16,
            filters:filtersToSend
        }
            
        if(searchKey&&page>1){
           
            body.search_key=searchKey
        }
      
        if(persona_id){
           
            body.persona_id=persona_id;
            body.personalisation=true
        }
        else{
            body.personalisation=false
        }

     
    return  axios.post(`${process.env.REACT_APP_API_GATEWAY}${SIMILARITY_V2}` , body,{headers ,cancelToken:SOURCE.token})
 
    }