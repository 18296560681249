import { useEffect, useState } from "react"
import '../baseLayout.css'
import SideBarComponent from "../ui-components/SideBarComponent"
import ProgressBarComponent from '../ui-components/ProgressBarComponent'
import ErrorMessageComponent from '../component/ErrorMessageComponent'
import { getPersonalShop, getSimilarsList } from "../services/clientManagementService"
import { textSeacrh, textSeacrhHistory } from "../services/textSearchService"
import { Chip, Pagination } from '@mui/material';
import Card from '../ui-components/Card';
import Personalization from '../component/personalization/Personalization';
import { IMAGEURL, SOURCE, resetSource, source, } from "../config"
import { useNavigate, useParams } from 'react-router-dom';
import { findSimilars } from "../services/similarService"
import { useSelector } from "react-redux"
import axios from "axios"
import PersonalShopSection from "../component/PersonlaShopSection"


export default function TextSearch() {
    const shop=useSelector((state)=> state.shop)
    const wishList=useSelector((state)=> state.shop.wishList)
    const basket=useSelector((state)=> state.shop.basket)
    let [inputValue, setInputValue] = useState("")
    let [result, setResult] = useState([])
    let [count, setCount] = useState(null)
    let [currentPage, setCurrentPage] = useState(1)
    const [filters, setFilters] = useState({
        website:'All',
        target_audience:'All',
        department:'All',
    })

    let [showProgressbar, setShowProgressbar] = useState()
    let [errorMessage, setErrorMessage] = useState()
    let [showHistory, setShowHistory] = useState()
    let [listOfSearchHistory, setHistoryList] = useState()
    let [noDataMsg, setNoDataMsg] = useState()
    let [noDataIcon, setNoDataIcon] = useState()
    let [lastDate, setLastDate] = useState()
    let [firstDate, setFirstDate] = useState()
    const { product_Id,title } = useParams()
    const navigate = useNavigate();

    const [fetch, setFetch] = useState(false) 
    const [searchKey, setSearchKey] = useState()
    const isPersonalized=useSelector((state)=> state.user.isPersonalized)
    const persona=useSelector((state)=> state.user.persona)



useEffect(()=>{
  
    if(inputValue!=='')
    {
        ResultofTextSearch(inputValue,1,'')
    }



   
    
},[isPersonalized,persona])

    useEffect(() => {
        
           if(inputValue!==''&& !product_Id){
            ResultofTextSearch(inputValue,1,'')
            }
     

        if (product_Id) {
            findSimilarsItems(1,'')
        }


        getSimilarsList(filters).then((response) => {
            if (response.data) {
                setLastDate(new Date(response?.data?.last_product_date))
                setFirstDate(new Date(response?.data?.first_product_date))

            }
        }).catch((err) => {
            console.log(err)
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetch, product_Id])

useEffect(()=>{


    if(result.length>0)
    {
        editResult(result)
    }
},[shop.wishList,shop.basket])



    useEffect(() => {

        textSeacrhHistory().then((res) => {
            setHistoryList(res.data.history)
        }).catch((err) => {
            console.log(err);
        })

    }, [])


    function editResult(result2){
       

     let news= result2.map((product)=>{

        let index=  shop.wishList.findIndex((p)=> p.product_id===product.product_id)
               let isWishList=false;
               let isBasket=false;
              
       if(index!==-1){
         
        isWishList=true
       }

         index=  shop.basket.findIndex((p)=> p.product_id===product.product_id)
         
         if(index!==-1)
         {
            isBasket= true
         }
         return {...product,basket:isBasket,wishList:isWishList}
        
      })
        
      setResult(news)
    }


    async function handleEnterKey(event) {
        if (event.key === 'Enter') {
            await ResultofTextSearch(inputValue, 1)
        }
    }

    function showHistoryList() {
        if (listOfSearchHistory)
            setShowHistory(true)
    }


    function clickOnHistoryItems(e) {
        setInputValue(e.target.textContent)
        setShowHistory(false)
        ResultofTextSearch(e.target.textContent, 1)
    }

  
    async function ResultofTextSearch(value,page,search_key){
     
        setCurrentPage(page)
        setShowProgressbar(true)
        setShowHistory(false)
        setResult([])

      
        textSeacrh(value,filters,page,product_Id?'15':'16',search_key,isPersonalized?persona._id:'').then((response)=>{
            if(response.status===200) {

            if(response.data.message.length !== 0){
                    //  setResult(response.data.message)   
                     editResult(response?.data?.message)
                     setCount(response.data.count)
                     setSearchKey(response.data.search_key)
                     
            }
            else{
                setNoDataIcon('../NoQualifiedData.svg')
                setNoDataMsg('There’s no more data in this category. We’re brining in more data soon!')
                 setCount(null)
            }
        } else if(response.status===204){
            
            setCount(null)
            setNoDataIcon('../oops.svg')
            setNoDataMsg('No Data Found')
            
        }
        setShowProgressbar(false)

        }).catch((error) => {

            setShowProgressbar(false)
            setErrorMessage(error.message)
        })

    }


 


    async function findSimilarsItems(page,search_key) {

        setResult([])
        setCurrentPage(page);
        setShowProgressbar(true)

        findSimilars(filters, page, product_Id,search_key,isPersonalized?persona._id:'' ).then((response) => {

            if (response.status === 200) {
                if (response.data.message.length !== 0) {

                    // setResult(response?.data?.message)
                    editResult(response?.data?.message)
                    setCount(response?.data?.count ?? 0)
                    setSearchKey(response?.data?.search_key)

                } else {
                    setCount(0)
             setNoDataIcon('../NoQualifiedData.svg')
                setNoDataMsg('There’s no more data in this category. We’re brining in more data soon!')

                }
            }
            else if (response.status === 204) {
                setCount(0)
                setNoDataIcon('../oops.svg')
                setNoDataMsg('No Data Found')
            }

            setShowProgressbar(false)

        }).catch((error) => {

            if (axios.isCancel(error)) {
                console.log('Request canceled:', error.message);
              } else {
                setErrorMessage(error.message)
                setShowProgressbar(false)
              }
           
        })


    }


    function handelPagintation(page) {

        if (product_Id) {

            findSimilarsItems(page,searchKey)
        }
        else {
             
                    ResultofTextSearch(inputValue, page,searchKey)
            
   
        }
    }

    function back(){
        SOURCE.cancel('cancled') ;
        navigate(-1); 
        resetSource();
    }


    return (

        <div className="container-fluid">
            {errorMessage ? <ErrorMessageComponent message={errorMessage} /> : (
                <div className="row">
                     <div className="col-2"></div>
                     <SideBarComponent  setFetch={setFetch} selectedFilters={filters} setSelectedFilters={setFilters}></SideBarComponent>

                    <div className="col-12 col-lg-9 mx-auto  mt-5">
                        {/* first row that contain title and back button */}

                        <p className=" text-center   mx-auto w-100" style={paragraphStyle}>Neural Text Search for Fashion </p>

                        {/******************* fisrt and last date of items ********************/}
                        {result?.length > 0 && <p className="text-center ">

                            {firstDate ? <span >The first item added  : <span className="px-1" style={{ background: "#F3F3F3" }}> {firstDate.toDateString()}</span> </span> : <></>}
                            {lastDate ? <span className="d-block d-sm-inline" >The last item added : <span className="px-1" style={{ background: "#F3F3F3" }}>{lastDate.toDateString()}</span> </span> : <></>}
                        </p>}

                        {/************************  search bar ************************/}
                        {!product_Id && <>
                            <div className="d-flex col-12 col-md-7 mx-auto mt-3 justify-content-between ">

                                <p style={describeSpan}>Describe any thing in your mind </p>
                                <p style={exSpan}> (ex. Edgy rocker look for live concert)</p>

                            </div>
                            <div className="row col-md-7 mb-4  mx-auto position-relative">

                                <div className="input-group rounded " onClick={showHistoryList}>
                                    <div className="input-group-prepend">
                                        <span className="input-group-append bg-white">
                                            <button className="btn border-none border-left-0" type="button" onClick={() => { ResultofTextSearch(inputValue, 1) }}><i className="fas fa-search" style={{ color: "#BCB6B6" }}></i></button>
                                        </span>
                                    </div>
                                    <input type="text" className="form-control rounded" placeholder="Search" aria-label="Search" value={inputValue} aria-describedby="search-addon" style={{ paddingLeft: '40px', zIndex: 0 }} onKeyDown={handleEnterKey} onChange={(e) => { setInputValue(e.target.value) }} />
                                </div>

                                {showHistory ?

                                    <ul className="histDiv" >
                                        {listOfSearchHistory.map((el,index) => {
                                            return <li key={el.query+index} style={liStyle} className="liItems" value={el.query} onClick={(e) => { clickOnHistoryItems(e) }}><img src="../history.png" style={{ marginRight: "20px" }} alt="history" />{el.query}</li>
                                        })}
                                    </ul>
                                    :
                                    <></>
                                }</div>

                        </>}

                        {/*********** personalization Row****************/}

                        <div className="px-2">
                            <Personalization />

                        </div>

                         {/************************ your personal shop ************************/}
                        {isPersonalized&& !inputValue&&!product_Id&&!result.length>0 &&<PersonalShopSection inputValue={inputValue} setInputValue={setInputValue} ResultofTextSearch={ResultofTextSearch} />}

                     
                        {/***********  Similars to image ****************/}
                        <div className="row">
                        {product_Id &&
                        
                                      <div className="col-3 mt-3 ">
                                        <div className="col-11">
                                        <button className=" btn border-0  mb-2 d-block me-auto" onClick={back} ><img src='/curved-back.svg' alt="back" /> Back </button>
                                         <img  className="card-image rounded-1  w-100" src={`${IMAGEURL}${product_Id}_0.jpg`} alt="img" ></img>
                                         <p className="mt-1 text-start text-over-fLow" > {title??''}</p> 
                                        </div>
                                        
                                      </div>
                                    }
                        {/*********** result of text search ****************/}
                        {showProgressbar ?
                            <div className="row m-auto    py-5 col-lg-9 ">
                               
                                <ProgressBarComponent></ProgressBarComponent>
                            </div>

                            :
                            (result?.length > 0 ?
                                <div className={ "row  my-4 " +(product_Id? 'col-lg-9':'w-100') }>
                                    
                                    {count &&<div className="mt-4">
                                        { product_Id&&<p  className=" text-start  mx-auto  mb-3" >Search results for this image</p>}
                                        <p  className=" text-start my-0 py-0   mx-auto" style={countNumStyle}>{count} items found </p>
                                    </div> }

                                    {result.map((product) => {
                                          
                                        return (
                                            <div key={product.product_id} className={" col-6  py-2 "+(product_Id? 'col-md-4':' col-md-3')  }>
                                                <Card p={product}  type={'text'}/>
                                            </div>
                                        )
                                    })}

                                    {Math.ceil(count / 16) > 1 && <Pagination count={Math.ceil(count / 16)} page={currentPage} className="d-flex mt-2  justify-content-center col-12" shape="rounded" onChange={(e, page) => { handelPagintation(page) }} />}

                                </div>
                                :
                                <div className="mt-5">
                                    {noDataMsg &&
                                        <>
                                            <img style={{ width: '100px', height: "100px" }} src={noDataIcon} width="0px" height="60px" alt='nodata'></img>
                                            <p style={{ marginTop: "8px", fontFamily: "Signika" }}>{noDataMsg}</p>
                                        </>}
                                </div>)}

                                </div>
                    </div>
                </div>
            )}
        </div>
    )

}




let paragraphStyle = {
    fontFamily: "Signika",
    fontWeight: "550",
    "lineHeight": "25px",
    "fontSize": "23px",
    "float": "left"
}

let countStyle = {
    fontFamily: "Signika",
    fontWeight: "500",
    "fontSize": "16px",

}
let countNumStyle = {
    fontFamily: "Signika",
    fontWeight: "400",
    "fontSize": "14px",
    color: "#888888"

}
let describeSpan ={
    fontFamily:"Signika",
    fontWeight:"100",
    "line-height":"20px",
    "font-size":"14px",
    "float":"left",
    color:"#7C8DB5",
    "margin-left":"2px"
}
let exSpan ={
    fontFamily:"Signika",
    fontWeight:"50",
    "line-height":"20px",
    "font-size":"14px",
    "float":"right",
    color:"#7C8DB5"
}



let liStyle = {
    "textAlign": "left",
    "marginBottom": "10px",
    "marginTop": "2px",
    "fontFamily": "Signika",
    "color": "#BCB6B6"
}
const chip={
    width:'fit-content'
}
