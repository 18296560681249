import { useEffect, useState } from "react"
import '../baseLayout.css'

import ProgressBarComponent from '../ui-components/ProgressBarComponent'
import ErrorMessageComponent from '../component/ErrorMessageComponent'
import { getPersonalShop, getQuieres } from "../services/clientManagementService"

import { Chip, Pagination } from '@mui/material';
import Card from '../ui-components/Card';


import { useSelector } from "react-redux"


export default function PersonalShopSection(props) {
    const shop=useSelector((state)=> state.shop)
   
  
    let [result, setResult] = useState([])
    let [queries, setQueries] = useState([])
    let [count, setCount] = useState(null)
    let [currentPage, setCurrentPage] = useState(1)
    let [showProgressbar, setShowProgressbar] = useState()
    let [errorMessage, setErrorMessage] = useState()
    let [noDataMsg, setNoDataMsg] = useState()
    let [noDataIcon, setNoDataIcon] = useState()
    const [searchKey, setSearchKey] = useState()
    const persona=useSelector((state)=> state.user.persona)




    useEffect(()=>{

        // getQuieres(persona._id).then((res)=>{
        //     setQueries(res.data.data)
        //   console.log(res.data.data)
        // })
        // .catch((err)=>{
        //   console.log(err)
        // })
        getPesronalShop(1,'')
    },[persona])
    


useEffect(()=>{

    if(result.length>0)
    {
        editResult(result)
    }
},[shop.wishList,shop.basket,count])


    function editResult(result2){
       

     let news= result2.map((product)=>{

        let index=  shop.wishList.findIndex((p)=> p.product_id===product.product_id)
               let isWishList=false;
               let isBasket=false;
              
       if(index!==-1){
         
        isWishList=true
       }

         index=  shop.basket.findIndex((p)=> p.product_id===product.product_id)
         
         if(index!==-1)
         {
            isBasket= true
         }
         return {...product,basket:isBasket,wishList:isWishList}
        
      })
        
      setResult(news)
    }


  

    async function getPesronalShop(page,search_key){
     
        setCurrentPage(page)
        setShowProgressbar(true)

        setResult([])

      
        getPersonalShop(page,persona._id,search_key).then((response)=>{
            if(response.status===200) {

            if(response.data.data.length !== 0){
                     
                     editResult(response?.data?.data)
                     setCount(response.data.count)
                     setSearchKey(response.data.search_key)
                     
            }
            else{
                setNoDataIcon('../NoQualifiedData.svg')
                setNoDataMsg('There’s no more data in this category. We’re brining in more data soon!')
                 setCount(null)
            }
        } 

        setShowProgressbar(false)

        }).catch((error) => {

            setShowProgressbar(false)
            setErrorMessage(error.message)
        })

    }

    return (

        <div className="container-fluid">
         
               <>
                        

                         {/************************ your personal shop ************************/}
                        {<div style={{ backgroundColor: '#FBFBF9' }} className='w-100 rounded-3 p-4  py-5 mx-auto my-4 '>
                           <p className="fs-3 fw-bold ">Your personal shop</p> 
                           <p className="fs-5 mb-4 ">Based on your preferences</p> 
                    
                      
                       {queries?.length>0 &&<>
                        <div className='row my-1 py-0  mt-5   align-items-center'>
                        <p className="col-2  mt-3 ">Top Searched </p>    
                        <div style={{width:'5px'}}><div  style={{width:'1.5px',height:'25px' ,background:'#DCE0DE'}}></div></div>
                        
                        
                        {/* <div className= 'row   col-9  overflow-x-auto'>
                            {
                                queries.map((item,index)=>{
                                    return(
                                        <Chip 
                                        key={index+item}
                                        style={chip}
                                              className={'me-2 '+ ( props.inputValue===item?'border-2 border-black':"")}
                                            onClick={function () { 
                                                props.setInputValue(item)
                                                props.ResultofTextSearch(item,1,'')

                                        }}
                                            size="md"
                                            label={item}
                                            variant="outlined"
                                                  />  )

                                })
                            }
                           
                              
                        </div> */}
                        </div>
                        
                       
                        </>}
                        </div>}

                     
                       
                        <div className="row">
                      
                        {/*********** result ****************/}
                        {showProgressbar ?
                            <div className="row m-auto    py-5 col-lg-9 ">
                               
                                <ProgressBarComponent></ProgressBarComponent>
                            </div>

                            :
                            (result?.length > 0 ?
                                <div className="row  my-4 'w-100" >
                                    
                                    {result.map((product) => {
                                          
                                        return (
                                            <div key={product.product_id} className=" col-6  py-2  col-md-3" >
                                                <Card p={product}  type={'text'}/>
                                            </div>
                                        )
                                    })}

                                    {Math.ceil(count / 16) > 1 && <Pagination count={Math.ceil(count / 16)} page={currentPage} className="d-flex mt-2  justify-content-center col-12" shape="rounded" onChange={(e, page) => {  getPesronalShop(page,searchKey)  }} />}

                                </div>
                                :
                                <div className="mt-5">
                                    {noDataMsg &&
                                        <>
                                            <img style={{ width: '100px', height: "100px" }} src={noDataIcon} width="0px" height="60px" alt='nodata'></img>
                                            <p style={{ marginTop: "8px", fontFamily: "Signika" }}>{noDataMsg}</p>
                                        </>}
                                </div>)}

                                </div>
                                </>
            
        </div>
    )

}





const chip={
    width:'fit-content'
}
